import BulletList from '@tiptap/extension-bullet-list';
import { toggleList } from '../composables/utils';
export default BulletList.extend({
  name: 'bulletList',
  addAttributes() {
    return {
      ...this.parent?.(),
      textAlign: {
        default: 'inherit',
        parseHTML: element => element.style.textAlign || 'inherit',
        renderHTML: attributes => {
          if (!attributes.textAlign) {
            return {};
          }

          return {
            style: `text-align: ${attributes.textAlign};`,
          };
        },
      },
    };
  },
  addCommands() {
    return {
      ...this.parent?.(),
      toggleBulletList: () => params => {
        return toggleList(params, this.name, this.options);
      },
    };
  },
});
