import { reactive, toRefs } from 'vue';
import { createEventHook } from '@vueuse/core';
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth';

import initializeFirebaseApp from './src/firebase';
import { setupAuthEvents } from './src/events';
import { installLoginRoute, setupRouterGuards } from './src/router';
import { getCustomToken, getUserInfo, linkSSOProfile } from './src/service';
import { Origin, Scope } from './src/enums';

import { type App, type Ref, unref } from 'vue';
import type { Auth } from 'firebase/auth';
import type { Router } from 'vue-router';
import type {
  AuthContext,
  AuthOptions,
  AuthState,
  SetupOptions,
  SSOState,
  UseAuthReturn,
  User,
} from './types';

export { Origin, Scope };
export type {
  AuthContext,
  AuthOptions,
  AuthState,
  SetupOptions,
  SSOState,
  UseAuthReturn,
};

// Default SSO state
const ssoState: SSOState = reactive({
  ssoEnabled: false,
  ssoLoginUrl: `${import.meta.env.VITE_API_BASE}/auth/sso/login/`,
  ssoLogoutUrl: `${import.meta.env.VITE_API_BASE}/auth/sso/logout/`,
  ssoProfileUrl: import.meta.env.VITE_SSO_PROFILE_LINK,
  ssoMatchId: null,
});

// Default authentication state
const authState: AuthState = reactive({
  origin: Origin.Demo,
  scope: [],
  authenticating: true,
  authenticated: false,
});

// Event hooks for login and logout
const onLogout = createEventHook<string>();
const onLogin = createEventHook<User>();

// Default AuthContext to avoid errors on inject
const authContext: AuthContext = {
  getCustomToken,
  getUserInfo: () => getUserInfo().asPromised(),
  linkSSOProfile: (ssoId: string) => linkSSOProfile(ssoId).asPromised(),
  /**
   * Signs in using a custom token.
   * @param token - The custom token for authentication.
   */
  signInWithCustomToken: async (token: string): Promise<void> => {
    const auth = useFirebase();
    await signInWithCustomToken(auth, token);
  },
};

/**
 * Logs out the current user and handles SSO logout or state reset.
 */
const logout = async (): Promise<void> => {
  const auth = useFirebase();
  const token = (await auth.currentUser?.getIdToken()) ?? '';
  await signOut(auth);
  await onLogout.trigger(token);

  if (ssoState.ssoEnabled) {
    // Perform Single Logout (SLO)
    window.location.href = `${ssoState.ssoLogoutUrl}?access_token=${token}`;
  } else {
    // Just clean the state
    window.location.reload();
  }
};

/**
 * Factory function to create the AuthService plugin.
 * @returns A Vue plugin object for authentication.
 */
export function createAuthService(router: Router) {
  initializeFirebaseApp();
  installLoginRoute(router);
  return {
    /**
     * Installs the AuthService plugin into a Vue application.
     * @param app - The Vue application instance.
     * @param options - Authentication options.
     */
    install: (app: App, options: AuthOptions): void => {
      if (!options.origin) {
        throw new Error('[AuthService] Missing required "origin" option.');
      }

      if (
        !options.scope ||
        !Array.isArray(options.scope) ||
        options.scope.length === 0
      ) {
        throw new Error('[AuthService] Missing required "scope" option.');
      }

      // Initialize auth state
      authState.origin = options.origin;
      authState.scope = options.scope;

      ssoState.ssoLoginUrl += authState.origin;
      ssoState.ssoLogoutUrl += authState.origin;

      if (authState.origin === Origin.Builder) {
        ssoState.ssoEnabled = false;
      }

      Object.defineProperty(app.config.globalProperties, '$auth', {
        enumerable: true,
        get: () => unref(authState),
      });

      Object.defineProperty(app.config.globalProperties, '$logout', {
        enumerable: true,
        get: () => logout,
      });

      const context: SetupOptions = {
        router,
        context: authContext,
        state: authState,
        meta: ssoState,

        onLogin,
        onLogout,
      };

      setupAuthEvents(context);
      setupRouterGuards(context);
    },
  };
}

/**
 * Provides reactive references for authentication-related state and actions.
 *
 * This composable returns:
 * - Reactive state references from `authState`.
 * - The `logout` method to log the user out.
 * - Event hooks for login and logout to register callbacks.
 *
 * @example
 * // Import the `useAuth` composable
 * import { useAuth } from '@amit/auth';
 *
 * // Use in a component or script setup
 * const { origin, scope, authenticated, authenticating, logout, onLogin, onLogout } = useAuth();
 *
 * // Access reactive state
 * console.log(authenticated.value); // Logs whether the user is authenticated
 * authenticating.value = false; // Update the authentication state
 *
 * // Call logout
 * logout().then(() => {
 *   console.log('User logged out');
 * });
 *
 * // Register login callback
 * onLogin(() => {
 *   console.log('User logged in');
 * });
 *
 * // Register logout callback
 * onLogout((token) => {
 *   console.log('User logged out with token:', token);
 * });
 *
 * @returns {UseAuthReturn} An object containing:
 * - Reactive references for authentication state.
 * - `logout`: A function to log out the user.
 * - `onLogin`: A function to register a callback for login events.
 * - `onLogout`: A function to register a callback for logout events.
 */
export function useAuth(): UseAuthReturn {
  return {
    ...toRefs(authState),
    logout,
    onLogin: onLogin.on,
    onLogout: onLogout.on,
  };
}

/**
 * Provides SSO-related reactive state.
 * @returns Reactive SSO state values.
 */
export function useAuthMeta(): { [K in keyof SSOState]: Ref<SSOState[K]> } {
  return toRefs(ssoState);
}

/**
 * Provides auth-related reactive state.
 * @returns Reactive Auth state values.
 */
export function useAuthState(): { [K in keyof AuthState]: Ref<AuthState[K]> } {
  return toRefs(authState);
}

/**
 * Provides the authentication context injected by the plugin.
 * If not available, returns the default AuthContext.
 * @returns The injected authentication context or default context.
 */
export function useAuthContext(): AuthContext {
  return authContext;
}

export function useFirebase(): Auth {
  return getAuth();
}
