<template>
  <MainLayout
    :user="accountStore.user"
    :school="accountStore.user.school"
    :system="accountStore.config">
    <RouterView v-slot="{ Component, route }">
      <Transition
        enter-active-class="transition transform ease-out duration-500"
        enter-from-class="opacity-0 scale-95"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="transition transform ease-in duration-300"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="opacity-0 scale-95">
        <KeepAlive>
          <div class="flex flex-col h-full">
            <component :is="Component" :key="route.name" />
          </div>
        </KeepAlive>
      </Transition>
    </RouterView>
  </MainLayout>
</template>

<script setup lang="ts">
import { MainLayout, usePrimaryLinks, useUserLinks } from '@amit/layout';
import { useAccountStore } from '/@/app/store/account';
import { useAuth } from '@amit/auth';
import emitter from '@amit/composables/src/useBus';
import { provide } from 'vue';

const accountStore = useAccountStore();

provide('events', emitter);

usePrimaryLinks([
  { title: 'הקורסים שלי', path: 'home', iconName: 'LayoutDashboard' },
  { title: 'ספריה', path: 'library', iconName: 'LaptopMinimal' },
  {
    title: 'בתי ספר',
    path: 'school-list',
    iconName: 'School2',
    role: 'admin',
  },
  { title: 'ניהול קבוצות', path: 'groups-list', iconName: 'UsersRound' },
  { title: 'תמיכה', id: 'intercom', iconName: 'MessageCircleQuestion' },
]);
useUserLinks([
  {
    text: 'מרחב אישי',
    to: { name: 'home' },
  },
  {
    text: 'חשבון שלי',
    enabled: !!accountStore.user?.sso_id,
    link: import.meta.env.VITE_SSO_PROFILE_LINK,
  },
  {
    text: 'התנתקות',
    event: 'user:logout',
  },
]);

const { logout } = useAuth();
emitter.on('user:logout', () => {
  logout();
});
</script>
