<script setup lang="ts">
import NotFound from '/@/views/NotFound.vue'; // Import your 404 component
import { useAccountStore } from '/@/app/store/account';
import { computed, ref, resolveComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuth } from '@amit/auth';

const LoadingOverlay = resolveComponent('LoadingOverlay');
const accountStore = useAccountStore();
const isLoading = ref(false);

const { onLogin } = useAuth();

const route = useRoute();
const router = useRouter();

onLogin(async userInfo => {
  accountStore.setUser(userInfo);
});

const authorized = computed(() => {
  const { requiresAuth, role } = route.meta;
  if (!requiresAuth) {
    return true;
  }

  return !role || accountStore.user?.role?.includes(role);
});
</script>

<template>
  <router-view v-if="!isLoading && authorized" :key="$route.path" />
  <NotFound v-if="!authorized && accountStore.user.id" />
  <notification-toast />
  <LoadingOverlay
    v-model:active="isLoading"
    :can-cancel="false"
    :is-full-page="true"/>
</template>

<style>
.viewer #menuAccessibility,
.viewer #VAopenMenu {
  @apply hidden;
}
</style>
