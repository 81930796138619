import { defineStore } from 'pinia';

export const useSystemStore = defineStore({
  id: 'system',
  state: () => ({
    isLoading: false,
  }),
  getters: {},
  actions: {},
});
