<template>
  <div class="flex flex-col gap-1 app-form-item w-full">
    <label v-if="hasLabel" :for="name" class="text-secondary-900 text-xs">
      {{ currentLabel }}
    </label>

    <slot />

    <Transition name="fade">
      <small
        v-if="errorMessage"
        class="flex items-center justify-start gap-2 text-red-300">
        <CircleAlertIcon
          :size="22"
          class="stroke-white fill-red-600 relative -start-0.5"/>
        <span class="text-sm pt-0.5">{{ errorMessage }}</span>
      </small>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { CircleAlertIcon } from 'lucide-vue-next';

const props = defineProps<{
  name?: string;
  label?: string;
  errorMessage?: string;
  modelValue?: string;
}>();

const hasLabel = computed(() => !!props.label);
const errorMessage = computed(() => props.errorMessage);

const currentLabel = computed(() => `${props.label || ''}`);
</script>
