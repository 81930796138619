import SelectOptions from '../menuItemComponents/SelectOptions.vue';
import ColorsOptions from '../menuItemComponents/ColorsOptions.vue';
import LinkOptions from '../menuItemComponents/LinkOptions.vue';
import ImageOptions from '../menuItemComponents/ImageOptions.vue';
import TooltipOptions from '../menuItemComponents/TooltipOptions.vue';
import InsertTable from '../menuItemComponents/InsertTable.vue';
import {
  directionOptions,
  alignmentsOptions,
  spacingOptions,
  typographyOptions,
  backgroundPalettes,
  highlightPalettes,
  textPalettes,
  getCleanClass,
  getEditor,
  isEditorRTL,
  isEnabled,
  hasNodeWithAttribute,
} from './utils';
import {
  ALargeSmall,
  Bold,
  ChevronsUpDown,
  Highlighter,
  ImagePlus,
  IndentDecrease,
  IndentIncrease,
  Info,
  Italic,
  Link2,
  List,
  ListOrdered,
  Palette,
  Quote,
  RemoveFormatting,
  SeparatorHorizontal,
  Square,
  Strikethrough,
  Table,
  Underline,
} from 'lucide-vue-next';
import { getMarkRange } from '@tiptap/vue-3';

interface EditorOption {
  icon?: any; // Assuming React component for icon
  title: string;
  text?: string;
  disabled?: boolean;
  loseFocus?: boolean;
  showArrow?: boolean;
  type?: 'select'; // Optional type property
  component?: any; // Optional React component for rendering
  props?: Record<string, any>; // Optional props object
  action?: (arg?: any) => void; // Optional action function with optional argument
  isActive?: () => boolean; // Optional isActive function
}

const createIndentAction = (id, isIncrease): EditorOption => {
  const editor = getEditor(id).value;
  const isRTL = isEditorRTL(id);

  const command = isIncrease ? 'indent' : 'outdent';
  return {
    disabled: !isEnabled(id, 'indent'),
    icon:
      (isRTL && !isIncrease) || (!isRTL && isIncrease)
        ? IndentIncrease
        : IndentDecrease,
    title:
      (isRTL && isIncrease) || (!isRTL && isIncrease)
        ? 'הזחה קדימה'
        : 'הזחה אחורה',
    action: () => {
      editor.chain().focus()[command]().run();
    },
    isActive: () => {
      //@ts-ignore
      return isIncrease ? !!hasNodeWithAttribute(id, 'indent') : false;
    },
  };
};

export function getOptions(
  id,
  disabledHighlight,
  alignmentRef,
  directionRef,
  typographyRef,
  activeTypo,
  getFontSizeOptions,
): { options: EditorOption[] }[] {
  const editor = getEditor(id)?.value;
  //@ts-ignore
  if (!editor) return [];
  return [
    {
      options: [
        {
          disabled: !isEnabled(id, 'direction'),
          icon: directionRef,
          title: 'כיוון טקסט',
          type: 'select',
          component: SelectOptions,
          props: {
            options: directionOptions,
            type: 'dir',
            action: option => {
              editor
                .chain()
                .focus()
                [option.actionMethod](option.attributes)
                .run();
              directionRef.value = option.icon;
            },
          },
        },
      ],
    },
    {
      options: [
        {
          disabled: !isEnabled(id, 'text_align'),
          icon: alignmentRef,
          title: 'יישור טקסט',
          type: 'select',
          component: SelectOptions,
          props: {
            type: 'textAlign',
            options: alignmentsOptions,
            action: option => {
              editor
                .chain()
                .focus()
                [option.actionMethod](option.attributes)
                .run();
              alignmentRef.value = option.icon;
            },
          },
        },
        createIndentAction(id, true),
        createIndentAction(id, false),
        {
          disabled: !isEnabled(id, 'line_height'),
          icon: ChevronsUpDown,
          title: 'מרווח בין שורות ופיסקאות',
          type: 'select',
          component: SelectOptions,
          props: {
            type: 'lineHeight',
            options: spacingOptions,
            action: option => {
              const { $from } = editor?.state?.selection ?? null;
              const node = $from.node();
              if (option.type == 'line') {
                getEditor(id)
                  .value.chain()
                  .focus()
                  .setLineHeight(option.attributes)

                  .run();
              } else {
                getEditor(id)
                  .value.chain()
                  .focus()
                  .updateAttributes(node.type.name, {
                    ...node.attrs,
                    class: getCleanClass(node, option),
                  })
                  .run();
              }
            },
            isActive: option => {
              const { $from } = editor?.state.selection ?? null;
              const node = $from.node();
              return editor?.isActive(node.type.name, {
                class: getCleanClass(node, option),
              });
            },
          },
        },
        {
          disabled: !isEnabled(id, 'bullet_list'),
          icon: List,
          title: 'רשימת תבליטים',
          action: () => editor?.chain().focus().toggleBulletList().run(),
          isActive: () => editor?.isActive('bulletList'),
        },
        {
          disabled: !isEnabled(id, 'ordered_list'),
          icon: ListOrdered,
          title: 'רשימת מספרים',
          action: () => editor?.chain().focus().toggleOrderedList().run(),
          isActive: () => editor?.isActive('orderedList'),
        },
        {
          disabled: !isEnabled(id, 'horizontal_rule'),
          icon: SeparatorHorizontal,
          title: 'קו חוצה/מחיצה',
          action: () =>
            editor
              .chain()
              .focus()
              .addSpaceBefore()
              .setHorizontalRule()
              .addSpaceAfter()
              .run(),
          isActive: () => editor?.isActive('hardBreak'),
        },
      ],
    },
    {
      options: [
        {
          disabled: !isEnabled(id, 'image_resize'),
          icon: ImagePlus,
          title: 'תמונה',
          type: 'select',
          component: ImageOptions,
          props: {
            action: src => {
              const isEmptyBlock =
                editor?.state.selection.$from.parent.content.size === 0;
              let chain = editor?.chain().focus();
              if (!isEmptyBlock) chain = chain.splitBlock();

              chain.setImage({ src }).run();
            },
          },
        },
        {
          disabled: !isEnabled(id, 'tooltip'),
          icon: Info,
          title: 'מילה חמה',
          type: 'select',
          loseFocus: true,
          component: TooltipOptions,
          props: {},
          action: data_tooltip => {
            if (data_tooltip) {
              const { state } = editor;
              const { empty, $cursor } = state.selection;

              if (!empty) {
                editor.chain().focus().setTooltip({ data_tooltip }).run();
              } else {
                const tr = state.tr;
                const tooltipMarkType = state.schema.marks.tooltip;
                const currentMarks = $cursor.marks();
                const tooltipMark = currentMarks.find(
                  mark => mark.type === tooltipMarkType,
                );
                const range = getMarkRange(
                  $cursor,
                  tooltipMarkType,
                  tooltipMark.attrs,
                );
                if (range) {
                  const from = range.from;
                  const to = range.to;
                  tr.addMark(
                    from,
                    to,
                    tooltipMarkType.create({ data_tooltip }),
                  );
                  editor?.view.dispatch(tr);
                }
              }
            } else {
              editor?.chain().focus().unsetTooltip().run();
            }
          },
          isActive: () => editor?.isActive('tooltip'),
        },
        {
          disabled: !isEnabled(id, 'link') || disabledHighlight,
          icon: Link2,
          title: 'קישור',
          type: 'select',
          loseFocus: true,
          component: LinkOptions,
          props: {},
          action: href => {
            if (href)
              getEditor(id)
                .value.chain()
                .focus()
                .setLink({ href, class: 'href' })
                .run();
            else {
              editor?.chain().focus().unsetLink().run();
            }
          },
          isActive: () => editor?.isActive('link'),
        },
        {
          disabled: !isEnabled(id, 'table'),
          icon: Table,
          title: 'טבלה',
          type: 'select',
          loseFocus: true,
          component: InsertTable,
          props: {},
          action: ({ rows, cols, withHeaderRow }) => {
            editor?.chain().focus().addSpaceBefore().run();
            editor.chain().insertTable({ rows, cols, withHeaderRow }).run();
            editor
              .chain()
              .insertContentAt(
                getEditor(id).value?.state.doc.content.size,
                '<p></p>',
              )
              .run();
          },
          isActive: () => editor?.isActive('table'),
        },
      ],
    },
    {
      options: [
        {
          disabled: !isEnabled(id, 'background_color'),
          icon: Square,
          title: 'צבע רקע',
          type: 'select',
          component: ColorsOptions,
          props: { palettes: backgroundPalettes, type: 'backgroundColor' },
          action: color => {
            color
              ? editor
                  .chain()
                  .focus()
                  .addSpaceBefore()
                  .setBackgroundColor({ color })
                  .run()
              : editor.chain().focus().unsetBackgroundColor().run();
          },

          isActive: () => editor?.isActive('highlight'),
        },
        {
          disabled: !isEnabled(id, 'highlight') || disabledHighlight,
          icon: Highlighter,
          title: 'סימון',
          type: 'select',
          component: ColorsOptions,
          props: { palettes: highlightPalettes, type: 'highlight' },
          action: color => {
            color
              ? editor.chain().focus().setHighlight({ color }).run()
              : editor?.chain().focus().unsetHighlight().run();
          },

          isActive: () => editor?.isActive('highlight'),
        },
        {
          disabled: !isEnabled(id, 'color'),
          icon: Palette,
          title: 'צבע טקסט',
          type: 'select',
          component: ColorsOptions,
          props: { palettes: textPalettes, type: 'textStyle' },
          action: color =>
            color
              ? editor?.chain().focus().setColor(color).run()
              : editor?.chain().focus().unsetColor(color).run(),
          isActive: () => getEditor(id).value?.isActive('color'),
        },
        {
          disabled: !isEnabled(id, 'bold'),
          icon: Bold,
          title: 'טקסט מודגש',
          action: () => editor?.chain().focus().toggleBold().run(),
          isActive: () => editor?.isActive('bold'),
        },
        {
          disabled: !isEnabled(id, 'italic'),
          icon: Italic,
          title: 'טקסט נטוי',
          action: () => editor?.chain().focus().toggleItalic().run(),
          isActive: () => editor?.isActive('italic'),
        },
        {
          disabled: !isEnabled(id, 'underline'),
          icon: Underline,
          title: 'קו תחתון',
          action: () => {
            editor?.chain().focus().toggleUnderline().run();
          },
          isActive: () => editor?.isActive('underline'),
        },
        {
          disabled: !isEnabled(id, 'strike'),
          icon: Strikethrough,
          title: 'קו חוצה',
          action: () => editor?.chain().focus().toggleStrike().run(),
          isActive: () => editor?.isActive('strike'),
        },
        {
          disabled: !isEnabled(id, 'block_quote'),
          icon: Quote,
          title: 'ציטוט',
          action: () => {
            let chain = editor?.chain().focus();
            if (!editor?.isActive('blockquote')) {
              chain = chain.addSpaceBefore(true);
            }
            return chain.toggleBlockquote().run();
          },
          isActive: () => editor?.isActive('blockquote'),
        },
      ],
    },
    {
      options: [
        {
          disabled: !isEnabled(id, 'text_type'),
          title: 'סוג טקסט',
          text: typographyRef,
          type: 'select',
          showArrow: true,
          component: SelectOptions,
          props: {
            alignmentCenter: false,
            options: typographyOptions,
            action: option => {
              const { $from } = editor?.state?.selection ?? null;
              const node = $from.node();
              editor
                .chain()
                .focus()
                [option.actionMethod]({
                  ...option.attributes,
                  class: getCleanClass(node, option),
                })
                .setFontSize(option.attributes.fontSize)
                .run();
              typographyRef.value = option.text;
            },
            isActive: option => {
              const { $from } = editor?.state?.selection ?? null;
              const node = $from.node();
              const type =
                option.actionMethod == 'setHeading' ? 'heading' : 'paragraph';
              return editor?.isActive(type, {
                class: getCleanClass(node, option),
              });
            },
          },
        },
        {
          disabled: !isEnabled(id, 'font_size'),
          icon: ALargeSmall,
          title: 'גודל טקסט',
          type: 'select',
          component: SelectOptions,
          props: {
            type: 'fontSize',
            options: getFontSizeOptions,
            action: option => {
              editor
                .chain()
                .focus()
                [option.actionMethod](option.attributes)
                .run();
            },
          },
        },
      ],
    },
    {
      options: [
        {
          disabled: !isEnabled(id, 'clear_text'),
          icon: RemoveFormatting,
          title: 'מחיקת עיצוב',
          action: () =>
            getEditor(id)
              .value.chain()
              .focus()
              .clearNodes()
              .unsetAllMarks()
              .run(),
          isActive: () => false,
        },
      ],
    },
  ];
}
