import { ref, shallowRef } from 'vue';
import { createEventHook } from '@vueuse/core';

import type { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import type { UseApiReturn } from '../types';

/**
 * A composable function for handling API requests with Axios.
 * Provides reactive state and event hooks for managing API responses and errors.
 *
 * @param request - The Axios promise representing the API request.
 * @param legacy - Trigger legacy error object
 * @returns An object with reactive state, event hooks, and helper methods for the API request.
 */
export function useApi<T = any, E = any>(
  request: AxiosPromise<T>,
  legacy: boolean = true,
): UseApiReturn<T, E> {
  const response = shallowRef<AxiosResponse<T>>();
  const data = shallowRef<T>();
  const isFinished = ref(false);
  const isLoading = ref(true);
  const error = shallowRef<E>();

  const fetchResult = createEventHook();
  const fetchError = createEventHook();

  request
    .then((r: AxiosResponse<T>) => {
      response.value = r;
      data.value = r.data;
      fetchResult.trigger(r.data);
    })
    .catch((e: AxiosError<E>) => {
      if (e.response) {
        // Request made and server responded
        const resData: any = e.response.data;

        let data = {
          status: e.response.status,
          code: resData.errorCode || resData.statusCode,
          message: resData.errorDescription || resData.message,
          details: resData.meta,
        };

        //@ts-ignore
        error.value = data;
        if (legacy) {
          //@ts-ignore
          fetchError.trigger(data);
        } else {
          fetchError.trigger(e);
        }
      } else if (e.request) {
        // The request was made but no response was received
        console.error(e.request);
        fetchError.trigger(e);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error', e.message);
        fetchError.trigger(e);
      }
    })
    .finally(() => {
      isLoading.value = false;
      isFinished.value = true;
    });

  return {
    response,
    data,
    error,
    onSuccess: fetchResult.on,
    onError: fetchError.on,
    finished: isFinished,
    loading: isLoading,
    asPromised: () => request.then(result => result.data),
  };
}
