<template>
  <div
    class="grid auto-rows-fr md:grid-cols-2 auto-cols-fr w-full h-screen p-7 relative overflow-y-auto bg-white">
    <ArrowLeftIcon
      v-if="!isCurrent('options') && ssoEnabled"
      class="fixed top-7 start-7 cursor-pointer text-secondary-700 rtl:rotate-180"
      @click="() => goTo('options')"/>

    <div
      class="max-w-sm w-full px-5 md:ps-0 mx-auto flex flex-col gap-4 justify-center">
      <div class="flex flex-col gap-2 text-secondary-900">
        <AmitLogo />
        <p class="font-semibold">
          {{ t('system.name') }} // {{ t(`system.roles.${origin}`) }}
        </p>
        <Transition name="slide">
          <h3
            :key="heading"
            class="text-gray-800 text-md font-anomaliaBold -mt-2">
            {{ heading }}
          </h3>
        </Transition>
      </div>

      <div
        v-if="isCurrent('match')"
        class="flex flex-col gap-2 text-secondary-900 my-2">
        <p class="text-sm my-2 font-extralight">{{ t('login.sso.warning') }}</p>
        <p class="text-sm font-bold">{{ t('login.sso.action') }}</p>
      </div>

      <Transition name="slide">
        <div v-if="isCurrent('options')" class="mt-20 flex flex-col gap-2">
          <LoginSSO />
          <BaseButton
            :label="t('login.options.local')"
            outlined
            severity="light"
            :aria-label="t('login.options.local')"
            @click="() => goTo('local')"/>
        </div>
        <LoginForm v-else :action="action" :match="isCurrent('match')" />
      </Transition>
    </div>

    <div
      class="hidden md:flex flex-col gap-15 items-center justify-center md:h-full rounded-3xl bg-[#fdebe7] overflow-hidden">
      <img
        class="object-cover h-full"
        alt="welcome amit"
        src="https://res.cloudinary.com/dcodkxpej/image/upload/q_auto,f_auto/colorful-geometric-pattern-background-handmade-prints_1_et89lf.jpg"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import AmitLogo from '../assets/amit-logo.svg';
import { ArrowLeftIcon } from 'lucide-vue-next';

import BaseButton from 'primevue/button';
import LoginForm from './LoginForm.vue';
import LoginSSO from './LoginSSO.vue';

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStepper } from '@vueuse/core';
import { useAuthMeta, useAuthState } from '../../';
import messages from '../locale';
import { useAuthCheck } from '../recaptcha';

const { ssoMatchId, ssoEnabled } = useAuthMeta();
const { origin } = useAuthState();
const { t } = useI18n(messages);

useAuthCheck();

const steps = {
  /**
   * First time we return from SSO,
   * the user is still not linked.
   *
   * So we need to complete the match
   * ?sso_id=xyz -> set sso_id on user profile
   */
  match: ssoMatchId.value,

  /**
   * When we have SSO enabled, we have multiple sign-in options
   * for user to choose from.
   */
  options: ssoEnabled.value,

  /**
   * But if we have only one option,
   * Skip selection and show the form
   */
  local: true, // Default case
};
// First 'True'
const initialStep = Object.keys(steps).find(
  key => steps[key as keyof typeof steps],
)!;
const { current, goTo, isCurrent } = useStepper(
  Object.keys(steps),
  initialStep,
);

const heading = computed(() =>
  current.value === 'options'
    ? t('login.options.select')
    : t('welcome.heading'),
);
const action = computed(() =>
  current.value === 'match'
    ? t('login.actions.match')
    : t('login.actions.submit'),
);
</script>

<style>
.grecaptcha-badge {
  z-index: 999 !important;
  bottom: 80px !important;
}
</style>
