/**
 * Enum for the possible scopes (roles) in the application.
 */
export enum Scope {
  Admin = 'admin',
  Teacher = 'teacher',
  Student = 'student',
  Editor = 'editor',
}

/**
 * Enum for the possible origins of the application.
 */
export enum Origin {
  Demo = 'demo',
  Builder = 'builder',
  Console = 'console',
  Students = 'students',
}
