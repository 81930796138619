<template>
  <ListGroup class="w-fit rounded-md !p-2">
    <ListGroupItem
      v-for="(palette, key) in palettes"
      :key="key"
      :hover="false"
      class="hover:bg-white border-b border-secondary-200 !p-1 cursor-auto"
    >
      <div class="grid grid-cols-3 p gap-2 w-fit py-1">
        <div
          v-for="color in //@ts-ignore
          palette?.colors"
          :key="color"
          class="hover:border hover:border-secondary-400 rounded-full w-5 h-5 flex items-center justify-center cursor-pointer"
          :style="{ background: color }"
          :class="{
            'border !border-secondary-600':
              model === color || isLightColor(color, '#FFFEEE'),
            'border-2 !border-secondary-500 flex p-2 cursor-not-allowed':
              isDisabled(color),
          }"
          @click="submitColor(model === color ? null : color)"
        >
          <input v-model="model" type="hidden" />
          <X :class="model === color ? 'stroke-secondary-600' : 'hidden'" />
        </div>
      </div>
    </ListGroupItem>
    <ListGroupItem class="hover:bg-white border-b-0 !p-1 !pt-2">
      <ColorsWheel class="cursor-not-allowed opacity-50" />
    </ListGroupItem>
  </ListGroup>
</template>
<script lang="ts" setup>
import { inject, onMounted, ref } from 'vue';
import { X } from 'lucide-vue-next';
import { onClickOutside } from '@vueuse/core';
//@ts-ignore
import { ListGroup, ListGroupItem } from '@';
import { useEditor } from '../composables/useEditor';
import ColorsWheel from '@amit/foundation/assets/colors-wheel.svg';
const { getSelected } = useEditor();
const props = defineProps({
  palettes: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Array,
    default: () => [],
  },
  type: {
    type: String,
    default: 'textStyle',
  },
  modelValue: {
    type: String,
    default: '#0F172A',
  },
});
const target = ref(null);
const model = ref(props.modelValue);
const emit = defineEmits(['action', 'close']);
const submitColor = color => {
  if (isDisabled(color)) return;

  model.value = color;

  emit('action', model.value);
  emit('close');
};

const isDisabled = color => {
  if (props.disabled.length) {
    return props.disabled.includes(color) || color === null;
  }
};

onMounted(() => {
  model.value = getSelected(inject('blockId'), props.type);
});

onClickOutside(target, () => emit('close'));

const isLightColor = (color, thresholdColor) => {
  // Convert hex to RGB
  const hexToRgb = hex => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  // Calculate relative luminance
  const luminance = (r, g, b) => {
    const a = [r, g, b].map(v => {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  };

  // Convert colors to RGB
  const rgb = hexToRgb(color);
  const thresholdRgb = hexToRgb(thresholdColor);

  if (!rgb || !thresholdRgb) {
    throw new Error('Invalid color format. Please use hex format.');
  }

  // Calculate luminance
  const lum = luminance(rgb.r, rgb.g, rgb.b);
  const thresholdLum = luminance(
    thresholdRgb.r,
    thresholdRgb.g,
    thresholdRgb.b,
  );

  // Compare luminance of the color with the threshold color
  return lum > thresholdLum;
};
</script>
