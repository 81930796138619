<template>
  <div
    v-show="widgetsVisible && !widgetsEmpty"
    class="widgets overflow-auto fixed top-10 bottom-0 bg-white left-0 lg:sticky lg:top-0 lg:bg-secondary-75 z-[100]">
    <TeleportTarget id="widgets-bar" class="flex flex-col gap-6 pb-10" />
  </div>
</template>

<script setup lang="ts">
import TeleportTarget from '@/apps/slideshow/teleport/TeleportTarget.vue';
import { useWidgets } from '../useLayout';
import { onMounted } from 'vue';

const { widgetsVisible, widgetsEmpty } = useWidgets();

onMounted(() => {
  let target = document.getElementById('widgets-bar');

  if (!target || !target?.childNodes) {
    return;
  }

  let observer = new MutationObserver(() => {
    const children = Array.from(target.childNodes).filter(
      node => node.nodeType != 3,
    );

    widgetsEmpty.value = children.length === 0;
  });

  observer.observe(target, {
    childList: true, // observe direct children
  });
});
</script>

<style scoped>
.widgets {
  @apply border-r-1 border-secondary-250 w-85 p-4;
}
</style>
