// Hebrew translation for academic years
export const hebrewYears = {
  2021: 'תשפ"א', // 2021-2022
  2022: 'תשפ"ב', // 2022-2023
  2023: 'תשפ"ג', // 2023-2024
  2024: 'תשפ"ד', // 2024-2025
  2025: 'תשפ"ה', // 2025-2026
  2026: 'תשפ"ו', // 2026-2027
  2027: 'תשפ"ז', // 2027-2028
  2028: 'תשפ"ח', // 2028-2029
  2029: 'תשפ"ט', // 2029-2030
  2030: 'תש"ץ', // 2030-2031
  2031: 'תשצ"א', // 2031-2032
  2032: 'תשצ"ב', // 2032-2033
  2033: 'תשצ"ג', // 2033-2034
  2034: 'תשצ"ד', // 2034-2035
};
