import { Mark } from '@tiptap/core';

export default Mark.create({
  name: 'lineHeight',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      lineHeight: {
        default: 'normal',
        parseHTML: element => element.style.lineHeight,
        renderHTML: attributes => {
          return attributes.lineHeight
            ? { style: `line-height: ${attributes.lineHeight}` }
            : {};
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[style]',
        getAttrs: element => {
          const lineHeight = element.style.lineHeight;
          return lineHeight ? { lineHeight } : false;
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { lineHeight, style, ...rest } = HTMLAttributes;
    const newStyle = style
      ? `${style}; line-height: ${lineHeight}`
      : `line-height: ${lineHeight}`;

    return ['span', { ...rest, style: newStyle }, 0];
  },
  //@ts-ignore
  addCommands() {
    return {
      setLineHeight:
        lineHeight =>
        ({ commands }) => {
          return commands.setMark(this.name, { lineHeight });
        },
      unsetlineHeight:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
      toggleLineHeight:
        lineHeight =>
        ({ commands, state }) => {
          const { from, to } = state.selection;
          let hasLineHeight = false;

          state.doc.nodesBetween(from, to, node => {
            if (
              node.marks.some(
                mark =>
                  mark.type.name === this.name &&
                  mark.attrs.lineHeight === lineHeight,
              )
            ) {
              hasLineHeight = true;
            }
          });

          if (hasLineHeight) {
            return commands.unsetMark(this.name);
          } else {
            return commands.setMark(this.name, { lineHeight });
          }
        },
    };
  },
});
