<template>
  <li
    class="p-3 my-2 rounded-md mb-0.5 last:mb-0 bg-secondary-75"
    :class="activeCondition && 'bg-slate-900'">
    <slot :handle-click="handleClick" :expanded="expanded" />
  </li>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'SidebarLinkGroup',
  props: {
    activeCondition: { type: Boolean },
  },
  setup(props) {
    const expanded = ref(props.activeCondition);

    const handleClick = () => {
      expanded.value = !expanded.value;
    };

    return {
      expanded,
      handleClick,
    };
  },
};
</script>
