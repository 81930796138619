import Paragraph from '@tiptap/extension-paragraph';
export interface ParagraphOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    paragraph: {
      /**
       * Toggle a paragraph
       */
      setParagraph: () => ReturnType;
    };
  }
}

export default Paragraph.extend({
  name: 'paragraph',
  key: 'paragraph',
  group: 'block',
  defining: true,
  content: 'inline*',
  addAttributes() {
    return {
      ...this.parent?.(),
      class: {
        default: 'mb-4 body',
        parseHTML: element => element.getAttribute('class') || '',
        renderHTML: attributes => {
          return {
            class: `${attributes.class}`,
          };
        },
      },
      textAlign: {
        default: 'inherit',
        parseHTML: element => element.style.textAlign || 'inherit',
        renderHTML: attributes => {
          if (!attributes.textAlign) {
            return {};
          }

          return {
            style: `text-align: ${attributes.textAlign}`,
          };
        },
      },
      fontSize: {
        default: '18px',
        parseHTML: element =>
          element.getAttribute('font-size') || element.style.fontSize || '18px',
        renderHTML: attributes => {
          if (!attributes.fontSize) {
            return {};
          }
          return {
            style: `font-size: ${attributes.fontSize}`,
            'font-size': attributes.fontSize,
          };
        },
      },
    };
  },
  //@ts-ignore
  addCommands() {
    return {
      setParagraph:
        attributes =>
        ({ editor, chain }) => {
          const { state } = editor;
          const { from } = state.selection;
          const node = state.doc.resolve(from).node(1);
          let textAlign = null;
          if (node) {
            textAlign = node.attrs.textAlign;
          }

          return chain()
            .setNode(this.name, { ...attributes, textAlign })
            .run();
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Alt-0': () => this.editor.commands.setParagraph(),
    };
  },
});
