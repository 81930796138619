<template>
  <NodeViewWrapper ref="target" class="tableWrapper">
    <table class="tiptap-table" :class="{ 'delete-table': toDelete }">
      <colgroup ref="colgroup"></colgroup>
      <NodeViewContent as="tbody" class="w-full" />
    </table>
    <div class="pt-2 h-12">
      <div
        v-show="editor.options.editable && editor.options.keyboard && isActive"
        class="mx-auto border-1 border-secondary-250 bottom-10 mt-2 rounded-md shadow-md p-1.5 bg-white w-fit flex items-center justify-center"
        @mousedown.stop="handleDropdownClick"
        @click.stop
      >
        <div title="למחוק את כל הטבלה" class="px-2 border-e">
          <Trash2
            class="cursor-pointer hover:bg-secondary-250 rounded-sm p-1"
            :size="30"
            color="#000"
            stroke-width="1.2"
            @click="deleteTable"
            @mouseover="toDelete = true"
            @mouseleave="toDelete = false"
          />
        </div>

        <BaseDropdown class="border-e px-2">
          <template #trigger>
            <div
              title="צבע גבול"
              class="p-1 hover:bg-secondary-250 rounded-sm flex gap-1.5"
            >
              <PencilLine
                class="cursor-pointer"
                :size="22"
                color="#000"
                stroke-width="1.2"
              />
              <ChevronDown :size="18" stroke-width="1.2" />
            </div>
          </template>
          <ColorsOptions
            v-show="isActive"
            :palettes="backgroundPalettes"
            type="tableCellBorderColor"
            @action="color => updateBorderColorOfSelection(color)"
          />
        </BaseDropdown>

        <BaseDropdown class="px-2">
          <template #trigger>
            <div
              title="צבע רקע"
              class="items-center p-1 hover:bg-secondary-250 rounded-sm flex gap-1.5"
            >
              <Square
                class="cursor-pointer"
                :size="22"
                color="#000"
                stroke-width="1.2"
              />
              <ChevronDown :size="18" stroke-width="1.2" />
            </div>
          </template>
          <ColorsOptions
            v-show="isActive"
            :palettes="backgroundPalettes"
            type="tableCellBackgroundColor"
            @action="color => updateBackgroundColorOfSelection(color)"
          />
        </BaseDropdown>
      </div>
    </div>
  </NodeViewWrapper>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { NodeViewWrapper, NodeViewContent } from '@tiptap/vue-3';
import { onClickOutside } from '@vueuse/core';
import ColorsOptions from '../menuItemComponents/ColorsOptions.vue';
import { BaseDropdown } from '@';
import { Trash2, PencilLine, Square, ChevronDown } from 'lucide-vue-next';
import { TextSelection } from '@tiptap/pm/state';
const props = defineProps(['node', 'updateAttributes', 'editor']);

const backgroundPalettes = [
  {
    colors: [
      '#DED4F7',
      '#C1ADF0',
      '#9774E7',
      '#CADCFC',
      '#9EC0FA',
      '#77A6F8',
      '#FBE6B1',
      '#F8D477',
      '#F4BE34',
      '#FDD3E6',
      '#F6ACCE',
      '#F075AC',
      '#F9E1E1',
      '#E58080',
      '#D63434',
      '#FFFFFF',
      '#495465',
    ],
  },
];
const target = ref();
const colgroup = ref(null);
const isActive = ref(false);
const toDelete = ref(false);
const updateTableSelection = () => {
  isActive.value = true;
  const { state } = props.editor;
  const { selection } = state;
  const pos = props.getPos();

  // Check if the current node is selected
  isActive.value =
    selection.from <= pos + props.node.nodeSize && selection.to >= pos;
};

const selectTable = () => {
  const pos = props.getPos();

  // Create a selection that covers the table node
  const selection = TextSelection.create(props.editor.state.doc, pos, pos + 2);

  const transaction = props.editor.state.tr.setSelection(selection);
  props.editor.view.dispatch(transaction);
};

onMounted(() => {
  props.editor.on('selectionUpdate', updateTableSelection);
});
onBeforeUnmount(() => {
  props.editor.off('selectionUpdate', updateTableSelection);
});

function handleDropdownClick(event) {
  event.stopPropagation();
  event.preventDefault();
}

const deleteTable = () => {
  props.editor.chain().deleteTable().run();
};

const updateBorderColorOfSelection = color => {
  props.editor.chain().focus().setCellAttribute('borderColor', color).run();
  selectTable();
};

const updateBackgroundColorOfSelection = color => {
  props.editor.chain().focus().setCellAttribute('backgroundColor', color).run();
  selectTable();
};

onClickOutside(target, () => (isActive.value = false));
</script>

<style>
.delete-table {
  @apply opacity-80 bg-red-300;
}
.delete-table td {
  @apply border !border-red-500 opacity-60;
}
.delete-table th {
  @apply border !border-red-500 opacity-40;
}

.tiptap table {
  border-collapse: separate;
  border-spacing: 0px;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}
.tiptap table th {
  @apply bg-secondary-100;
}
.tiptap table td {
  @apply bg-white;
}

.tiptap table td > *,
.tiptap table th > * {
  @apply text-start;
}

.tiptap table p {
  margin: 0;
}

.tiptap table ul,
.tiptap table ol {
  @apply p-2;
}
.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}
.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.tiptap table .column-resize-handle {
  @apply bg-primary-400;
  bottom: -2px;
  pointer-events: none;
  position: absolute;
  right: -3px;
  top: 0;
  width: 6px;
  z-index: 40;
}

.tiptap table {
  border-collapse: separate;
  border-spacing: -1px;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.tiptap table .selectedCell:after {
  z-index: 10;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  width: 100%;
  @apply bg-primary-200 border border-primary-400 opacity-50;
}

.tiptap table td,
.tiptap table th {
  @apply border-1 border-secondary-300 p-2;
  box-sizing: border-box;
  position: relative;
  vertical-align: top;
  min-width: 0.5em;
}

.tiptap table td > *,
.tiptap table th > * {
  @apply text-start;
}
</style>
