import { defineStore } from 'pinia';
import {
  listSchools,
  listSchoolGroups,
} from '/@/views/playground/school-service';
import { IEnrollment, IUser } from '/@/app/models/interfaces';
import { collect } from 'collect.js';
import { getUserInfo } from '@amit/auth/src/service';

declare function Upscope(
  command: 'updateConnection',
  data: {
    uniqueId: string | undefined;
    identities: string[];
  },
): void;

export const useAccountStore = defineStore({
  id: 'account',
  state: () => ({
    user: {} as IUser,
    grades: [] as any[],
    selectedGroup: '' as any,
    selectedCourse: {} as any,
    school: {} as any,
    config: {} as any,
    groups: [] as any,
    mentorshipGroups: [] as any,
    surveys: [] as any,
    isAuthenticated: false as boolean,
  }),
  getters: {
    courseLinks: state => {
      const disciplineMap = {};

      state.groups.forEach(entry => {
        entry.courses.forEach(course => {
          course.disciplines.forEach(discipline => {
            if (!disciplineMap[discipline]) {
              disciplineMap[discipline] = [];
            }
            disciplineMap[discipline].push({
              to: {
                name: 'course-progress',
                params: { course: course.id, group: entry.id },
              },
              text: course.caption,
              groupName: entry.name,
            });
          });
        });
      });

      return [
        ...Object.keys(disciplineMap).map(discipline => ({
          text: discipline,
          subLinks: disciplineMap[discipline],
        })),
      ];
    },
    flattenedCourses(state) {
      return state.groups.flatMap(group =>
        group.courses.map(course => ({ course, group })),
      );
    },
    flattenedSurveys(state) {
      return state.groups.flatMap(group =>
        group.surveys.map(course => ({
          course: {
            ...course,
            type: 'survey',
            disciplines: ['שאלון', ...(course.disciplines ?? [])],
          },
          group,
        })),
      );
    },
  },
  actions: {
    async getUser(force = false) {
      if (force) {
        const { data } = await getUserInfo().asPromised();
        this.setUser(data);
      }
      return Promise.resolve(this.user);
    },
    setUser(data) {
      if (data.role.includes('teacher')) {
        this.isAuthenticated = true;
      }

      this.user = data;
      this.config = { currentYear: data.currentYear };

      data.groups = data.groups.filter(group =>
        group.teachers.includes(data.id),
      );

      // @ts-ignore
      window.Intercom('boot', {
        id: data.id,
        name: data.fullName,
      });

      // @ts-ignore
      window.$fvIdentity = {
        id: data.id,
        name: `${data.fullName}`,
        disableReplaysForUser: import.meta.env.MODE !== 'production',
        school: data.school?.name,
        roles: data.role.concat(import.meta.env.MODE),
        env: data.school?.name, //import.meta.env.MODE,
      };

      Upscope('updateConnection', {
        uniqueId: data.id,

        identities: [data.fullName, data.school?.name],
      });

      // setTimeout(() => {
      //   window.Fullview.requestSupport()
      // }, 10000)

      this.groups = data.groups;
    },

    // Maybe selected course not needed ... TBD
    setSelectedCourseOutline(enrollment: IEnrollment) {
      this.selectedCourse.outline = collect(enrollment.summary)
        .map((summary: any) => {
          return {
            id: summary.lesson.id,
            lesson: summary.lesson.lesson,
            subject: summary.lesson.title,
            score: summary.score || 0,
            progress: summary.progress || 0,
          };
        })
        .toArray();
    },
    setMentorshipGroups() {
      const { onSuccess } = listSchools({ mentorship: true });
      onSuccess(({ data: schools }) => {
        if (schools.length) {
          const { onSuccess } = listSchoolGroups(schools[0].id);

          onSuccess(({ data }) => {
            this.mentorshipGroups = data;
          });
        }
      });
    },
    onUserLogout() {
      this.isAuthenticated = false;
      this.user = {} as IUser;
    },
  },
});
