<template>
  <div
    ref="targetEl"
    dir="rtl"
    class="fixed top-0 flex z-[1302] h-full w-full max-w-2xl transition-transform duration-200 -translate-x-full left-0 shadow-xl"
    tabindex="-1"
    aria-labelledby="drawer-left-label">
    <div
      class="flex flex-col overflow-hidden h-full w-full rounded-r-xl bg-secondary-200 border-r">
      <div
        class="flex h-12 relative border-b border-secondary-200 justify-between items-center gap-5 px-5 bg-white"
        dir="ltr">
        <XIcon
          v-if="showClose"
          class="cursor-pointer hover:text-primary"
          @click="
            emit('close');
            closeDrawer();
          "/>
        <span class="text-md font-simplerBold">{{ title }}</span>
      </div>
      <div class="flex-1 overflow-y-auto p-5 bg-white">
        <TeleportTarget id="drawer-slot" />
      </div>
      <div
        class="h-15 relative border-t border-secondary-200 p-5 bg-white flex gap-4 justify-end items-center">
        <BaseButton
          class="flex items-center justify-center w-18 h-8.5 rounded-3xl border !border-red-800 bg-white text-2xl font-simplerBold focus:outline-none"
          color="light"
          @click="
            emit('close');
            closeDrawer();
          ">
          <span class="text-red-800">ביטול</span>
        </BaseButton>
        <BaseButton
          class="flex items-center justify-center w-18 h-8.5 rounded-3xl bg-primary text-2xl font-simplerBold focus:outline-none"
          @click="onDrawerAction.trigger({})">
          <span class="text-white">שמירה</span>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Drawer } from 'flowbite';
import type { DrawerOptions } from 'flowbite';
import { onMounted, ref, watchEffect } from 'vue';
import { XIcon } from 'lucide-vue-next';
import { useDrawer } from '../useLayout';
import TeleportTarget from '@/apps/slideshow/teleport/TeleportTarget.vue';
import { onClickOutside } from '@vueuse/core';
import { BaseButton } from '@';

const drawer = ref();
const targetEl = ref<HTMLElement | null>(null);

const { showDrawer, title, showClose, closeDrawer, onDrawerAction } =
  useDrawer();

const emit = defineEmits(['open', 'close']);

onClickOutside(targetEl, e => {
  if (e.target?.classList.contains('swal-button')) {
    return;
  }
  closeDrawer();
});

const options: DrawerOptions = {
  placement: 'left',
  backdrop: true,
  bodyScrolling: false,
};

watchEffect(() => {
  if (showDrawer.value) {
    drawer.value?.show();
    emit('open');
  } else {
    drawer.value?.hide();
  }
});

onMounted(() => {
  if (targetEl.value) {
    drawer.value = new Drawer(targetEl.value as HTMLElement, options);
  }
});
</script>

<style>
[drawer-backdrop] {
  @apply backdrop-blur-sm backdrop-opacity-90 z-[1300];
}
</style>
