<template>
  <NodeViewWrapper as="span" :class="imageViewClass">
    <div
      :class="{
        'image-view__body--focused': selected,
        'image-view__body--resizing': resizing,
      }"
      class="relative image-view__body"
    >
      <img
        ref="img"
        :src="src"
        :title="node.attrs.title"
        :alt="node.attrs.alt"
        :width="width"
        :height="height"
        class="z-90 image-view__body__image"
        @click="selectImage"
      />
      <div
        v-if="editor?.isEditable"
        v-show="selected || resizing"
        class="image-resizer"
      >
        <span
          v-for="direction in resizeDirections"
          :key="direction"
          :class="`image-resizer__handler--${direction}`"
          class="image-resizer__handler"
          @mousedown="onMouseDown($event, direction)"
        />
      </div>
      <Transition name="fade" appear>
        <div
          v-if="selected && editor.options.editable"
          class="absolute -bottom-10 left-2 bg-white flex-row-reverse p-1 z-100 flex rounded-sm shadow-lg"
        >
          <component
            :is="type.icon"
            v-for="type in displayTypes"
            :key="type.display"
            :class="{
              //@ts-ignore
              [type.class]: true,
              'bg-secondary-200': node.attrs.display === type.display,
            }"
            class="hover:bg-secondary-200 p-1 cursor-pointer"
            :size="22"
            color="#000"
            stroke-width="1.2"
            :title="type.display"
            @click="applyDisplay(type.display)"
          />

          <ImageUp
            class="hover:bg-secondary-200 p-1 cursor-pointer"
            :class="{
              'pointer-events-none cursor-default opacity-50': !canMoveUpRef,
            }"
            :size="22"
            color="#000"
            stroke-width="1.2"
            title="move image up"
            @click="moveUp()"
          />

          <ImageDown
            class="hover:bg-secondary-200 p-1 cursor-pointer"
            :class="{
              'pointer-events-none cursor-default opacity-50': !canMoveDownRef,
            }"
            :size="22"
            color="#000"
            stroke-width="1.2"
            title="move image down"
            @click="moveDown()"
          />
        </div>
      </Transition>
    </div>
  </NodeViewWrapper>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import { ResizeObserver } from '@juggle/resize-observer';
import { onClickOutside } from '@vueuse/core';
import { ImageDisplay } from '../nodes/ImageResize';
import {
  GalleryHorizontalEnd,
  GalleryThumbnails,
  ImageUp,
  ImageDown,
} from 'lucide-vue-next';
const enum ResizeDirection {
  TOP_LEFT = 'tl',
  TOP_RIGHT = 'tr',
  BOTTOM_LEFT = 'bl',
  BOTTOM_RIGHT = 'br',
}

const MIN_SIZE = 20;
const MAX_SIZE = 100000;

type ImageResult = {
  complete: boolean;
  width: number;
  height: number;
  src: string;
};

interface ImageCache {
  [key: string]: ImageResult;
}

const IMAGE_CACHE: ImageCache = {};

const resolveImg = (src: string): Promise<ImageResult> => {
  return new Promise((resolve, reject) => {
    const result: ImageResult = {
      complete: false,
      width: 0,
      height: 0,
      src,
    };

    if (!src) {
      reject(result);
      return;
    }

    if (IMAGE_CACHE[src]) {
      resolve({ ...IMAGE_CACHE[src] });
      return;
    }

    const img = new Image();

    img.onload = () => {
      result.width = img.width;
      result.height = img.height;
      result.complete = true;

      IMAGE_CACHE[src] = { ...result };
      resolve(result);
    };

    img.onerror = () => {
      reject(result);
    };

    img.src = src;
  });
};

const props = defineProps(nodeViewProps);
const selected = ref(false);
const resizing = ref(false);
const maxSize = ref({ width: MAX_SIZE, height: MAX_SIZE });
const originalSize = ref({ width: 0, height: 0 });
const resizerState = ref({ x: 0, y: 0, w: 0, h: 0, dir: '' });
const resizeDirections = [
  ResizeDirection.TOP_LEFT,
  ResizeDirection.TOP_RIGHT,
  ResizeDirection.BOTTOM_LEFT,
  ResizeDirection.BOTTOM_RIGHT,
];
let resizeOb: ResizeObserver;

const src = computed(() => props.node.attrs.src);
const width = computed(() => props.node.attrs.width);
const height = computed(() => props.node.attrs.height);
const display = computed(() => props.node.attrs.display);
const imageViewClass = computed(() => [
  'z-90',
  'image-view',
  `image-view--${display.value}`,
]);

const displayTypes = [
  {
    display: ImageDisplay.BREAK_TEXT,
    icon: GalleryThumbnails,
  },
  {
    display: ImageDisplay.FLOAT_LEFT,
    icon: GalleryHorizontalEnd,
    class: 'rotate-180',
  },
  {
    display: ImageDisplay.FLOAT_RIGHT,
    icon: GalleryHorizontalEnd,
  },
];
const applyDisplay = display => {
  props.updateAttributes({
    display,
  });
};

const canMoveUpRef = ref(true);
const canMoveDownRef = ref(true);
const canMoveUp = () => {
  //@ts-ignore
  return props.editor.commands.canMoveUp();
};

const canMoveDown = () => {
  //@ts-ignore
  return props.editor.commands.canMoveDown();
};
const moveUp = () => {
  selectImage();
  //@ts-ignore
  props.editor.commands.moveImageUp();
};
const moveDown = () => {
  selectImage();
  //@ts-ignore
  props.editor.commands.moveImageDown();
};
const selectImage = () => {
  if (selected.value) return;
  canMoveUpRef.value = canMoveUp();
  canMoveDownRef.value = canMoveDown();
  props.editor.commands.setNodeSelection(props.getPos());
  selected.value = true;
};

const getMaxSize = () => {
  const { width } = getComputedStyle(props.editor.view.dom);
  maxSize.value.width = parseInt(width, 10);
};

const onMouseDown = (e: MouseEvent, dir: ResizeDirection) => {
  e.preventDefault();
  e.stopPropagation();

  resizerState.value.x = e.clientX;
  resizerState.value.y = e.clientY;

  const originalWidth = originalSize.value.width;
  const originalHeight = originalSize.value.height;
  const aspectRatio = originalWidth / originalHeight;

  let { width, height } = props.node.attrs;
  const maxWidth = maxSize.value.width;

  if (width && !height) {
    width = width > maxWidth ? maxWidth : width;
    height = Math.round(width / aspectRatio);
  } else if (height && !width) {
    width = Math.round(height * aspectRatio);
    width = width > maxWidth ? maxWidth : width;
  } else if (!width && !height) {
    width = originalWidth > maxWidth ? maxWidth : originalWidth;
    height = Math.round(width / aspectRatio);
  } else {
    width = width > maxWidth ? maxWidth : width;
  }

  resizerState.value.w = width;
  resizerState.value.h = height;
  resizerState.value.dir = dir;

  resizing.value = true;

  onEvents();
};

const clamp = (val: number, min: number, max: number): number => {
  if (val < min) {
    return min;
  }
  if (val > max) {
    return max;
  }
  return val;
};

const onMouseMove = (e: MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  if (!resizing.value) return;

  const { x, y, w, h, dir } = resizerState.value;

  const dx = (e.clientX - x) * (/l/.test(dir) ? -1 : 1);
  const dy = (e.clientY - y) * (/t/.test(dir) ? -1 : 1);

  props.updateAttributes({
    width: clamp(w + dx, MIN_SIZE, maxSize.value.width),
    height: Math.max(h + dy, MIN_SIZE),
  });
};

const onMouseUp = (e: MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
  if (!resizing.value) return;

  resizing.value = false;

  resizerState.value = { x: 0, y: 0, w: 0, h: 0, dir: '' };

  offEvents();
  selectImage();
};

const onEvents = () => {
  document.addEventListener('mousemove', onMouseMove, true);
  document.addEventListener('mouseup', onMouseUp, true);
};

const offEvents = () => {
  document.removeEventListener('mousemove', onMouseMove, true);
  document.removeEventListener('mouseup', onMouseUp, true);
};

const handleSelectionUpdate = () => {
  const { selection } = props.editor.state;
  const { from, to } = selection;

  // Check if the node position is within the current selection range
  const nodePos = props.getPos();
  if (nodePos >= from && nodePos < to) {
    selectImage(); // Set the image as selected
  } else {
    selected.value = false; // Deselect the image
  }
};

onMounted(async () => {
  const result = await resolveImg(src.value);

  if (!result.complete) {
    result.width = MIN_SIZE;
    result.height = MIN_SIZE;
  }

  originalSize.value = { width: result.width, height: result.height };

  resizeOb = new ResizeObserver(() => {
    getMaxSize();
  });
  resizeOb.observe(props.editor.view.dom);
  props.editor.on('selectionUpdate', handleSelectionUpdate);
});

onBeforeUnmount(() => {
  resizeOb?.disconnect();
  props.editor.off('selectionUpdate', handleSelectionUpdate);
});

const img = ref();
onClickOutside(img, () => (selected.value = false));
</script>

<style scoped>
.image-view {
  @apply inline-block float-none leading-none my-3 max-w-full select-none align-baseline;
}

.image-view--inline {
  @apply ml-3 mr-3;
}

.image-view--block {
  @apply block;
}

.image-view--left {
  @apply float-left ml-0 mr-3;
}

.image-view--right {
  @apply float-right ml-3 mr-0;
}

.image-view__body {
  @apply clear-both inline-block max-w-full transition-all duration-200 ease-in relative;
}

.image-view__body--focused:hover,
.image-view__body--resizing:hover {
  /* Add styles here if needed */
}

.image-view__body__placeholder {
  @apply h-full left-0 absolute top-0 w-full;
}

.image-view__body__image {
  @apply cursor-pointer m-0;
}

.image-resizer {
  @apply border border-primary h-full left-0 absolute top-0 w-full z-10;
}

.image-resizer__handler {
  @apply bg-primary border border-white rounded-sm box-border block h-2 absolute w-2 z-20;
}

.image-resizer__handler--tl {
  @apply cursor-nw-resize left-[-4px] top-[-4px];
}

.image-resizer__handler--tr {
  @apply cursor-ne-resize right-[-4px] top-[-4px];
}

.image-resizer__handler--bl {
  @apply cursor-sw-resize left-[-4px] bottom-[-4px];
}

.image-resizer__handler--br {
  @apply cursor-se-resize right-[-4px] bottom-[-4px];
}
</style>
