<template>
  <FormItem :name :label :error-message="errorMessage" :extra>
    <Password
      v-model="value"
      :feedback="false"
      :aria-labelledby="name"
      :invalid="!!errorMessage"
      :disabled
      :placeholder
      toggle-mask
      :size="size ?? 'small'"/>
  </FormItem>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';

import Password from 'primevue/password';
import FormItem from './FormItem.vue';

type InputProps = {
  name: string;
  label: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  size?: 'large' | 'small';
  extra?: string;
};

const props = defineProps<InputProps>();
const { value, errorMessage } = useField<string>(() => props.name);
</script>
