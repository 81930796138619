import { Extension } from '@tiptap/core';
import { isAtEndOfMarkOrNode } from '../composables/utils';

export default Extension.create({
  name: 'GeneralKeyboardExtensions',

  addKeyboardShortcuts() {
    return {
      Space: ({ editor }) => {
        const types = ['link', 'tooltip']; // Add any other types you want to include
        const { state, view } = editor;
        const { schema, selection } = state;
        const { from, empty, $from } = selection;
        const isAtEnd = isAtEndOfMarkOrNode(state);
        if (!empty || !isAtEnd) {
          return false;
        }

        const markTypes = types.map(type => schema.marks[type]).filter(Boolean);
        const activeMarks = state.storedMarks || $from.marks();
        const activeMarkTypes = markTypes.filter(markType =>
          markType.isInSet(activeMarks),
        );

        if (activeMarkTypes.length > 0) {
          const tr = state.tr;

          // Insert a space at the current position
          tr.insertText(' ', from);

          console.log('sp here to remove or not to remove ', markTypes);
          // Remove all stored marks to ensure next text is unmarked
          markTypes.forEach(markType => {
            tr.removeStoredMark(markType);
          });

          view.dispatch(tr);
          return true;
        }
        return false;
      },
    };
  },
});
