import { Mark, mergeAttributes } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';

export default Mark.create({
  name: 'tooltip',

  group: 'inline',
  inline: true,

  addAttributes() {
    return {
      data_tooltip: {
        default: '',
        parseHTML: element => element.getAttribute('data-tooltip'),
        renderHTML: attributes => ({
          'data-tooltip': attributes.data_tooltip,
          class: 'cursor-pointer relative tooltip-text',
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-tooltip]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(HTMLAttributes), 0];
  },
  //@ts-ignore
  addCommands() {
    return {
      toggleTooltip:
        attributes =>
        ({ commands }) => {
          return commands.toggleMark(this.name, attributes);
        },
      setTooltip:
        attributes =>
        ({ commands }) => {
          return commands.setMark(this.name, attributes);
        },
      unsetTooltip:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('tooltipEventListener'),
        view(editorView) {
          let tooltipTimeout;

          const handleMouseEnter = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            const tooltipText = target?.getAttribute('data-tooltip');
            if (tooltipText) {
              tooltipTimeout = setTimeout(() => {
                showTooltip(event, tooltipText);
              }, 300); // 300ms delay
            }
          };

          const handleMouseLeave = () => {
            clearTimeout(tooltipTimeout);
            hideTooltip();
          };

          editorView.dom.addEventListener('mouseenter', handleMouseEnter, true);
          editorView.dom.addEventListener('mouseleave', handleMouseLeave, true);

          return {
            destroy() {
              editorView.dom.removeEventListener(
                'mouseenter',
                handleMouseEnter,
                true,
              );
              editorView.dom.removeEventListener(
                'mouseleave',
                handleMouseLeave,
                true,
              );
            },
          };
        },
      }),
    ];
  },
});

// Tooltip handling functions
function showTooltip(event: MouseEvent, tooltipText: string) {
  // Avoid creating multiple tooltips
  hideTooltip();

  const tooltipElement = document.createElement('div');
  tooltipElement.className = 'tooltip';
  tooltipElement.textContent = tooltipText;
  document.body.appendChild(tooltipElement);

  // First, temporarily add the tooltip to calculate its dimensions
  tooltipElement.style.position = 'absolute';
  tooltipElement.style.visibility = 'hidden';
  document.body.appendChild(tooltipElement);

  const tooltipWidth = tooltipElement.offsetWidth;

  // Now, remove it to reset the visibility
  tooltipElement.style.visibility = 'visible';

  // Adjust the tooltip positioning to center it around the mouse cursor
  tooltipElement.style.top = `${event.clientY + 10}px`; // Place it 20px below the mouse cursor
  tooltipElement.style.left = `${event.clientX - tooltipWidth / 2}px`; // Center the tooltip horizontally
  tooltipElement.style.zIndex = '999999999';
}

function hideTooltip() {
  const tooltipElement = document.querySelector('.tooltip');
  if (tooltipElement) {
    tooltipElement.remove();
  }
}
