import { isAtEndOfNode } from '@tiptap/core';

import {
  AlignRight,
  AlignLeft,
  PilcrowLeft,
  PilcrowRight,
  AlignCenter,
  AlignJustify,
} from 'lucide-vue-next';
import { ref } from 'vue';

export const textPalettes = [
  {
    colors: ['#C2F4CF', '#EBF391', '#D6F2B5', '#49DF94', '#CCDC18', '#8FDC38'],
  },
  {
    colors: ['#FADCB2', '#FDD3E6', '#DED4F7', '#F4BE34', '#F075AC', '#9774E7'],
  },
  {
    colors: ['#D63434', '#5192F6', '#CCDC18', '#FFFFFF', '#1E232A', '#67768E'],
  },
];
export const highlightPalettes = [
  {
    colors: [
      '#1E232A',
      '#495465',
      '#FFFFFF',
      '#0FA9FF',
      '#52C1FF',
      '#0093E5',
      '#F0920F',
      '#B82626',
      '#3F9F44',
    ],
  },
  {
    colors: ['#49DF94', '#8FDC38', '#26D9D9', '#9774E7', '#F075AC', '#5192F6'],
  },
];
export const backgroundPalettes = [
  {
    colors: [
      '#E5FAEA',
      '#F7FAD1',
      '#ECF9DC',
      '#C2F4CF',
      '#EBF391',
      '#D6F2B5',
      '#49DF94',
      '#CCDC18',
      '#8FDC38',
    ],
  },
  {
    colors: [
      '#FDF4D8',
      '#FDEDF4',
      '#EBE5FA',
      '#FADCB2',
      '#FDD3E6',
      '#DED4F7',
      '#F4BE34',
      '#F075AC',
      '#9774E7',
    ],
  },
];

export const typographyOptions = [
  {
    text: 'טקסט ענק',
    classes: 'display_md',
    actionMethod: 'setParagraph',
    attributes: { class: 'display_md', fontSize: '32px' },
  },
  {
    text: 'כותרת עמוד',
    classes: 'title_xl',
    actionMethod: 'setHeading',
    attributes: { level: 1, class: 'h1', fontSize: '24px' },
  },
  {
    text: 'כותרת משנית/משימה ראשית',
    classes: 'title_md',
    actionMethod: 'setHeading',
    attributes: { level: 3, class: 'h3', fontSize: '20px' },
  },
  {
    text: 'טקסט פסקה',
    classes: 'body',
    actionMethod: 'setParagraph',
    attributes: { class: 'body', fontSize: '18px' },
  },
];
export const fontSizeOptions = [
  {
    text: '12',
    actionMethod: 'setFontSize',
    attributes: '12px',
    applyTo: ['body'],
  },
  {
    text: '14',
    actionMethod: 'setFontSize',
    attributes: '14px',
    applyTo: ['body'],
  },

  {
    text: '16',
    actionMethod: 'setFontSize',
    attributes: '16px',
    applyTo: ['body', 'h1', 'h3'],
  },
  {
    text: '18',
    actionMethod: 'setFontSize',
    attributes: '18px',
    applyTo: ['body', 'h1', 'h3'],
  },
  {
    text: '20',
    actionMethod: 'setFontSize',
    attributes: '20px',
    applyTo: ['body', 'h1', 'h3'],
  },
  {
    text: '22',
    actionMethod: 'setFontSize',
    attributes: '22px',
    applyTo: ['body', 'h1', 'h3'],
  },
  {
    text: '24',
    actionMethod: 'setFontSize',
    attributes: '24px',
    applyTo: ['body', 'h1', 'h3'],
  },
  {
    text: '26',
    actionMethod: 'setFontSize',
    attributes: '26px',
    applyTo: ['h1', 'h3', 'display_md'],
  },
  {
    text: '28',
    actionMethod: 'setFontSize',
    attributes: '28px',
    applyTo: ['display_md'],
  },
  {
    text: '30',
    actionMethod: 'setFontSize',
    attributes: '30px',
    applyTo: ['display_md'],
  },
  {
    text: '32',
    actionMethod: 'setFontSize',
    attributes: '32px',
    applyTo: ['display_md'],
  },
  {
    text: '34',
    actionMethod: 'setFontSize',
    attributes: '34px',
    applyTo: ['display_md'],
  },
  {
    text: '36',
    actionMethod: 'setFontSize',
    attributes: '36px',
    applyTo: ['display_md'],
  },
];

export const alignmentsOptions = [
  {
    icon: AlignRight,
    actionMethod: 'setTextAlign',
    attributes: 'right',
  },
  {
    icon: AlignCenter,
    actionMethod: 'setTextAlign',
    attributes: 'center',
  },
  {
    icon: AlignLeft,
    actionMethod: 'setTextAlign',
    attributes: 'left',
  },
  {
    icon: AlignJustify,
    actionMethod: 'setTextAlign',
    attributes: 'justify',
  },
];
export const directionOptions = [
  {
    icon: PilcrowLeft,
    actionMethod: 'setDirection',
    attributes: 'rtl',
  },
  {
    icon: PilcrowRight,
    actionMethod: 'setDirection',
    attributes: 'ltr',
  },
];
export const spacingOptions = [
  {
    text: 'ריווח בין שורות',
    disabled: true,
    classes: 'font-simplerBold',
  },
  {
    text: '1.15',
    attributes: 1.15,
    type: 'line',
    default: true,
  },
  {
    text: '1.5',
    attributes: 1.5,
    type: 'line',
  },
  {
    text: 'כפול',
    attributes: 2,
    type: 'line',
  },
  {
    text: 'ריווח בין פסקאות',
    disabled: true,
    classes: 'font-simplerBold',
  },
  {
    text: '20',
    attributes: { class: 'mb-4' },
    type: 'paragraph',
    default: true,
  },
  {
    text: '25',
    attributes: { class: 'mb-6' },
    type: 'paragraph',
  },
  {
    text: '30',
    attributes: { class: 'mb-8' },
    type: 'paragraph',
  },
];

export const editors = new Map();

export const getEditor = id => {
  return editors.get(id)?.editor || null;
};

export const isEditorRTL = id => {
  return editors.get(id).direction == 'rtl';
};

export const isEnabled = (id, extension) => {
  return (
    editors.get(id).enables[extension] !== false &&
    editors.get(id).enables[`hide_${extension}`] !== false
  );
};

export const getCleanClass = (node, option) => {
  const styles = ['display_md', 'body', 'h1', 'h3'];
  const classes = `${node.attrs.class} ${option?.attributes?.class ?? ''}`;
  let classesArray = classes.split(/\s+/);
  let otherClasses = classesArray.filter(
    cls => !cls.startsWith('mb-') && !styles.includes(cls),
  );
  let lastMbClass = classesArray.filter(cls => cls.startsWith('mb-')).pop();

  if (lastMbClass) {
    otherClasses.push(lastMbClass);
  } else {
    otherClasses.push('mb-4');
  }

  const lastStyleClass = classesArray.filter(cls => styles.includes(cls)).pop();
  if (lastStyleClass) {
    otherClasses.push(lastStyleClass);
  }
  return otherClasses.join(' ').trim();
};

export const toggleList = (
  { commands, chain, state, dispatch },
  listType,
  options,
) => {
  const { tr } = state;
  const { from, to } = tr.selection;
  const changes = [];
  const firstNode = tr.doc.nodeAt(0);
  tr.doc.nodesBetween(from, to, (node, pos, parent) => {
    if (parent === tr.doc) {
      //@ts-ignore
      changes.push({
        pos,
        type: node.type,
        attrs: { ...node.attrs, indent: 0 },
      });
    }
  });
  const firstListItemAttributes = firstNode
    ? {
        indent: firstNode.attrs.indent,
        textAlign: firstNode.attrs.textAlign,
      }
    : {};

  changes.forEach(change => {
    //@ts-ignore
    tr.setNodeMarkup(change.pos, change.type, change.attrs);
  });
  dispatch(tr);

  if (options.keepAttributes) {
    return chain()
      .toggleList(listType, options.itemTypeName, options.keepMarks)
      .updateAttributes(listType, {
        ...firstListItemAttributes,
      })
      .run();
  }
  return commands.toggleList(listType, options.itemTypeName, options.keepMarks);
};

export const getParentTopLevel = (
  $from: any,
): { topLevelPos: number; topLevelNode: any } => {
  let pos = $from.pos;
  let depth = $from.depth;

  // Traverse up to the top level node
  while (depth > 0) {
    pos = $from.before(depth);
    depth--;
  }
  const topLevelPos = pos;
  const topLevelNode = $from.doc.nodeAt(topLevelPos);

  return { topLevelPos, topLevelNode };
};

export const isFirstNode = $from => {
  if ($from.pos <= 1) return true;
  return false;
};

export const isLastNode = ($from, tr) => {
  const { nextNodePos } = getNextNode(tr, $from.pos);
  return nextNodePos < 0;
};

export const getNextNode = (
  tr,
  currentPos,
  depth = null,
  excludeTypes = ['tableHeader', 'tableRow', 'tableCell'],
) => {
  let parentPos = -1;
  let found = false;
  let nextNode;
  let nextNodePos = -1;
  let nFound = false;
  let currentNodedepth = 0;

  tr.doc.nodesBetween(0, tr.doc.content.size, (node, pos, parent, pPos) => {
    if (!excludeTypes.includes(node.type.name)) {
      if (!found && (currentPos == pos || currentPos - 1 === pos)) {
        parentPos = pPos;
        currentNodedepth = depth ?? tr.doc.resolve(currentPos).depth;
        found = true;
      }
      if (found && nFound && tr.doc.resolve(pos).depth <= currentNodedepth) {
        nextNode = node;
        nextNodePos = pos;
        found = false;
      }
      if (found) {
        nFound = true;
      }
    }
  });

  return { nextNode, nextNodePos, parentPos };
};

export const getPreviousNode = (tr, currentPos, depth, excludeTypes = []) => {
  let parentPos = -1;
  let found = false;
  let prevNode;
  let prevNodePos = -1;
  let currentNodedepth = 0;

  tr.doc.nodesBetween(0, tr.doc.content.size, (node, pos, parent, pPos) => {
    //@ts-ignore
    if (!excludeTypes.includes(node.type.name)) {
      if (currentPos == pos) {
        parentPos = pPos;
        found = true;
        currentNodedepth = depth ?? tr.doc.resolve(currentPos).depth;
      }
      if (!found && tr.doc.resolve(pos).depth == currentNodedepth) {
        prevNode = node;
        prevNodePos = pos;
      }
    }
  });
  return { prevNode, prevNodePos, parentPos };
};

export const isAtEndOfMarkOrNode = state => {
  const { $from } = state.selection;

  const isEndOfNode = isAtEndOfNode(state);

  // Check if the position is at the end of any active marks
  const activeMarks = $from.marks();
  const isAtEndOfMark = activeMarks.some(mark => {
    const markEnd = $from.nodeAfter
      ? $from.nodeAfter.marks.find(m => m.type === mark.type) === undefined
      : true;
    return markEnd;
  });

  return isEndOfNode || isAtEndOfMark;
};

export const hasNodeWithAttribute = (id, attributeName) => {
  const editor = getEditor(id).value;
  if (!editor) return;
  const state = editor.state;
  const { from } = state.selection;
  let found = false;
  const node = state.doc.resolve(from).node(1);
  if (node?.attrs[attributeName]) {
    return true; // stop traversing once we find a match
  }
  return found;
};

export const isSelectionEmpty = ref(false);
export const configureEditorStyle = id => {
  if (!getEditor(id)) return;
  const options = { highlightColor: '#9dc1fb', textColor: 'white' };
  const { empty } = getEditor(id)?.value?.state.selection;
  isSelectionEmpty.value = empty;

  if (isSelectionEmpty.value) return;
  console.log('getEditor', getEditor(id));
  getEditor(id)
    .value?.chain()
    .setHighlight({ color: options.highlightColor })
    .setColor(options.textColor)
    .run();
};
export const undoEditorStyle = id => {
  if (!getEditor(id)) return;
  if (isSelectionEmpty.value) return;
  console.log(getEditor(id).value.chain());
  getEditor(id).value?.chain()?.undo()?.run();
};
