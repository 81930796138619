import { Node } from '@tiptap/core';

export default Node.create({
  name: 'hardBreak',

  inline: true,

  group: 'inline',

  selectable: false,

  parseHTML() {
    return [{ tag: 'br' }];
  },

  renderHTML() {
    return ['br'];
  },
  //@ts-ignore
  addCommands() {
    return {
      setHardBreak:
        () =>
        ({ dispatch, state }) => {
          const { selection, tr } = state;
          const { from } = selection;
          const marks = state.storedMarks || state.doc.resolve(from).marks();

          // Insert the hard break
          tr.insert(from, state.schema.nodes.hardBreak.create());

          // Reapply the marks
          if (marks.length) {
            marks.forEach(mark => {
              tr.addMark(from, from + 1, mark);
            });
          }

          dispatch(tr.scrollIntoView());
          return true;
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      //@ts-ignore
      'Shift-Enter': () => this.editor.commands.setHardBreak(),
    };
  },
});
