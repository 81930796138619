<template>
  <FormControl v-bind="props">
    <div class="inline-flex relative w-full h-full">
      <div
        :id="triggerId"
        ref="trigger"
        class="inline-flex items-center w-full">
        <slot-listener
          tabindex="0"
          role="button"
          @keyup.enter="onToggle"
          @click="onToggle">
          <slot name="trigger" :triggered="visible" :on-toggle="onToggle">
            <Button color="gray" outline square size="lg" class="w-full">
              {{ text }}
              <template #suffix>
                <svg
                  class="w-4 h-4 ml-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"></path>
                </svg>
              </template>
            </Button>
          </slot>
        </slot-listener>
      </div>
      <Teleport to="body" :disabled="!fixed">
        <Transition :name="transitionName" :css="showTransition">
          <div
            v-if="visible"
            id="dropdown-content"
            ref="content"
            :style="`${contentStyles} ${
              fullWidth
                ? `width: ${trigger?.getBoundingClientRect()?.width ?? 0}px`
                : ''
            }`"
            :class="[contentClasses]">
            <OnClickOutside
              :options="{ ignore: [`#${triggerId}`] }"
              @trigger="onHide">
              <slot-listener @click="onSlotClick && onHide()">
                <slot />
              </slot-listener>
            </OnClickOutside>
          </div>
        </Transition>
      </Teleport>
    </div>
  </FormControl>
</template>
<script lang="ts" setup>
import { computed, ref, toRef } from 'vue';
import type { PropType } from 'vue';
import type { DropdownPlacement, AlignPlacement } from './types';
import { useDropdownClasses } from './composables/useDropdownClasses';
import Button from '../Button/BaseButton.vue';
import { OnClickOutside } from '@vueuse/components';
import SlotListener from '@/components/utils/SlotListener/SlotListener.vue';
import FormControl from '@/components/utils/FormControl/FormControl.vue';
import { nanoid } from 'nanoid';

const props = defineProps({
  placement: {
    type: String as PropType<DropdownPlacement>,
    default: 'bottom',
  },
  align: {
    type: String as PropType<AlignPlacement>,
    default: null,
  },
  text: {
    type: String,
    default: '',
  },
  transition: {
    type: [String, null] as PropType<string | null>,
    default: null,
  },
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  onSlotClick: {
    type: Boolean,
    default: true,
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: null,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  showTransition: {
    type: Boolean,
    default: true,
  },
  fixed: {
    type: Boolean,
    default: true,
  },
  toggle: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['blur']);

const placementTransitionMap: Record<DropdownPlacement, string> = {
  bottom: 'to-bottom',
  left: 'to-left',
  right: 'to-right',
  top: 'to-top',
};

const visible = ref(false);
const triggerId = ref(`trigger-${nanoid()}`);

const onHide = () => {
  visible.value = false;
  emit('blur');
};
const onToggle = () => (visible.value = props.toggle ? !visible.value : true);

const transitionName = computed(() => {
  if (props.transition === null) return placementTransitionMap[props.placement];
  return props.transition;
});

const content = ref<HTMLDivElement>();
const trigger = ref<HTMLDivElement>();

const { contentClasses, contentStyles } = useDropdownClasses({
  placement: toRef(props, 'placement'),
  fixed: toRef(props, 'fixed'),
  align: toRef(props, 'align'),
  visible,
  contentRef: content,
  triggerRef: trigger,
});
</script>
