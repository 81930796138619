<template>
  <ListGroup
    ref="target"
    class="w-fit max-w-80 border border-secondary-250 shadow-none"
    :class="{
      '!border-primary': isValidURL,
      '!border-red-500': model && !isValidURL,
    }"
  >
    <ListGroupItem class="!px-2 hover:bg-white" :hover="false">
      <div class="flex gap-2 items-center">
        <div class="flex gap-2">
          <Link2 />
          <input
            ref="linkInput"
            v-model="model"
            :placeholder="'כאן מודבק הקישור'"
            class="focus:ring-0 focus:outline-none"
            autofocus
            @keyup="canVisit = false"
            @keyup.enter="handleEnter"
          />
        </div>
        <div class="flex gap-1">
          <CircleCheck
            v-show="model && isValidURL && !canVisit"
            class="cursor-pointer stroke-primary"
            :size="16"
            @click="submitLink"
          />
          <a
            v-show="model && canVisit"
            :href="computedUrl()"
            target="_blank"
            @click="emit('close')"
          >
            <ArrowLeft class="cursor-pointer stroke-primary" :size="16" />
          </a>
          <CircleX
            v-show="model"
            class="cursor-pointer stroke-red-500"
            :size="16"
            @click="deleteLink"
          />
        </div>
      </div>
    </ListGroupItem>
  </ListGroup>
</template>
<script lang="ts" setup>
import { ArrowLeft, CircleCheck, CircleX, Link2 } from 'lucide-vue-next';
import { computed, inject, nextTick, onMounted, ref } from 'vue';
//@ts-ignore
import { ListGroup, ListGroupItem } from '@';
import { useEditor } from '../composables/useEditor';
import { get } from 'lodash';

const model = ref('');
const canVisit = ref(false);
const { getCurrentMark } = useEditor();
import * as linkify from 'linkifyjs';
import { onClickOutside } from '@vueuse/core';
const emit = defineEmits(['action', 'close']);

const submitLink = () => {
  emit('close');
  model.value = computedUrl();
  emit('action', model.value);
};

const deleteLink = () => {
  emit('close');
  model.value = '';
  emit('action', model.value);
};

const linkInput = ref();

const isValidURL = computed(() => {
  return linkify.test(model.value);
});

const computedUrl = () => {
  const url = model.value.trim();
  if (!url.match(/^https?:\/\//)) {
    return `https://${url}`;
  }
  return url;
};

onMounted(() => {
  model.value = get(
    getCurrentMark(inject('blockId'), 'link'),
    'mark.attrs.href',
    '',
  );
  if (model.value) {
    canVisit.value = true;
  }
  nextTick(() => {
    linkInput.value.focus();
    linkInput.value.select();
  });
});

const handleEnter = () => {
  if (!model.value) {
    deleteLink();
  } else if (isValidURL.value) {
    submitLink();
  }
  return;
};

const target = ref();
onClickOutside(target, () => {
  emit('close');
});
</script>
