<template>
  <NodeViewWrapper
    ref="cell"
    class="relative"
    :class="{
      'cell-to-delete-row': toDeleteRow,
      'cell-to-delete-col': toDeleteCol,
    }"
    :as="node.attrs.type"
    :style="{
      backgroundColor: node.attrs.backgroundColor,
      borderColor: node.attrs.borderColor,
    }"
    :colspan="node.attrs.colspan"
    :rowspan="node.attrs.rowspan"
    :colwidth="node.attrs.colwidth"
  >
    <NodeViewContent />

    <div
      v-if="
        //@ts-ignore
        isCellSelected && editor.options.editable && editor.options.keyboard
      "
      ref="dropdownRef"
      class="absolute top-1 left-1 actions-menu z-10"
      @mousedown.stop="handleDropdownClick"
    >
      <BaseDropdown placement="right">
        <template #trigger>
          <div
            class="w-5 h-5 rounded-sm flex items-center justify-center border-2 border-white bg-secondary-250 hover:bg-primary-200"
          >
            <ChevronDown :size="16" color="#000" stroke-width="1.2" />
          </div>
        </template>
        <ListGroup class="w-50 rounded-md p-2">
          <ListGroupItem
            v-for="(action, index) in actions"
            :key="index"
            class="hover:bg-secondary-200 !py-1.5 !px-1 gap-2 rounded-md border-b-0 shadow-none"
            @click="action.action"
            @mouseover="action?.mouseover"
            @mouseleave="action?.mouseleave"
          >
            <component
              :is="action.icon"
              :size="14"
              color="#000"
              stroke-width="1.5"
            />
            <p>{{ action.text }}</p>
          </ListGroupItem>
        </ListGroup>
      </BaseDropdown>
    </div>
  </NodeViewWrapper>
</template>
<script setup lang="ts">
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3';
import { ref, watch } from 'vue';
import {
  ChevronDown,
  PanelRightOpen,
  PanelTopOpen,
  Trash2,
} from 'lucide-vue-next';
//@ts-ignore
import { BaseDropdown, ListGroup, ListGroupItem } from '@';
const isCellSelected = ref(false);
const props = defineProps(nodeViewProps);
const toDeleteRow = ref(false);
const toDeleteCol = ref(false);
const addColumnAfter = () => {
  props.editor.commands.addColumnAfter();
};
const addRowAfter = () => {
  props.editor.commands.addRowAfter();
};
const deleteColumn = () => {
  if (!props.editor.commands.deleteColumn())
    props.editor.chain().deleteTable().run();

  const allCells = document.querySelectorAll('.selectedCell');

  allCells.forEach(cell => {
    cell.classList.remove('cell-to-delete-col');
  });
};
const deleteRow = () => {
  if (!props.editor.commands.deleteRow())
    props.editor.chain().deleteTable().run();
};

const toggleRowClass = action => {
  toDeleteRow.value = action == 'add';
  const selectedCell = cell.value.$el; // Get the selected cell
  if (selectedCell) {
    const table = selectedCell.closest('table'); // Get the closest table
    const allSelectedCells = table.querySelectorAll('.selectedCell');

    allSelectedCells.forEach(cell => {
      cell.classList[action]('cell-to-delete-row'); // Add the class 'cell-to-delete-col' to each selected cell
    });
  }
};

const toggleColumnClass = action => {
  toDeleteCol.value = action === 'add';
  const selectedCell = cell.value.$el;
  const selectedCells = [
    selectedCell,
    ...Array.from(
      cell.value.$el.closest('table').querySelectorAll('.selectedCell'),
    ),
  ];

  if (selectedCells.length > 0) {
    // Get all indexes of selected cells
    const cellIndexes = selectedCells.map(
      selectedCell => selectedCell.cellIndex,
    );
    const table = selectedCells[0].closest('table');

    // Apply class toggling to selected cells
    selectedCells.forEach(cell => {
      cell.classList[action]('cell-to-delete-col');
    });

    // Apply class toggling to header cells based on cell indexes
    cellIndexes.forEach(index => {
      const headerCell = table.querySelector(`th:nth-child(${index + 1})`);
      headerCell?.classList[action]('cell-to-delete-col');
    });

    // Add class to the entire column for all rows
    for (const row of table.rows) {
      cellIndexes.forEach(index => {
        if (row.cells[index]) {
          row.cells[index].classList[action]('cell-to-delete-col'); // Add class to the entire column
        }
      });
    }

    toDeleteCol.value = false;
  }
};

const actions = [
  { text: 'הוספת עמודה', icon: PanelRightOpen, action: addColumnAfter },
  { text: 'הוספת שורה', icon: PanelTopOpen, action: addRowAfter },
  {
    text: 'מחיקת עמודה',
    icon: Trash2,
    action: deleteColumn,
    mouseover: () => toggleColumnClass('add'),
    mouseleave: () => toggleColumnClass('remove'),
  },
  {
    text: 'מחיקת שורה',
    icon: Trash2,
    action: deleteRow,
    mouseover: () => toggleRowClass('add'),
    mouseleave: () => toggleRowClass('remove'),
  },
];

function handleDropdownClick(event) {
  event.stopPropagation();
  event.preventDefault();
}

function getTableCellNodeStartEnd(editor, position) {
  const { doc } = editor.state;
  const resolvedPos = doc.resolve(position);
  for (let depth = resolvedPos.depth; depth >= 0; depth--) {
    const node = resolvedPos.node(depth);
    if (node.type.name === 'tableCell' || node.type.name === 'tableHeader') {
      const start = resolvedPos.start(depth) - 1;
      const end = resolvedPos.end(depth);
      return { start, end };
    }
  }
  return null;
}

const dropdownRef = ref();
function updateNodeView() {
  const { state } = props.editor;
  const { from } = state.selection;
  const cellPos = props.getPos();
  const cellEnd = cellPos + props.node.nodeSize;
  const cellData = getTableCellNodeStartEnd(props.editor, from);

  if (!cellData) {
    return;
  }

  isCellSelected.value = from >= cellPos && from <= cellEnd;
}

const cell = ref();

watch(
  () => props.editor.state.selection,
  () => {
    updateNodeView();
    if (cell.value && cell.value.$el.classList?.contains('selectedCell')) {
      isCellSelected.value = true;
    }
  },
);
</script>

<style scoped>
.group:has(.block[dir='ltr']) .actions-menu,
.preview-wrapper[dir='ltr'] .actions-menu {
  @apply right-1 left-auto;
}

tr:has(.cell-to-delete-row) {
  @apply bg-white;
}

tr:has(.cell-to-delete-row) td {
  @apply border !border-red-500 !bg-red-300 opacity-60;
}
tr:has(.cell-to-delete-row) th {
  @apply border !border-red-500 !bg-red-500 opacity-40;
}

th.cell-to-delete-col {
  @apply border !border-red-500 !bg-red-500 opacity-40;
}
td.cell-to-delete-col {
  @apply border !border-red-500 !bg-red-300 opacity-60;
}
</style>
