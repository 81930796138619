export default {
  messages: {
    he: {
      login: {
        title: 'התחברות',
        sso: {
          warning:
            'זיהינו שזו כניסתך הראשונה. יש לקשר בין החשבון בסביבת הלמידה לחשבון במשרד החינוך.',
          action: 'נא למלא את שם המשתמש והסיסמה הקיימים בסביבת הלמידה:',
        },
        options: {
          select: 'איך תרצה להתחבר?',
          local: 'הזדהות שם משתמש וסיסמה',
          sso: 'הזדהות משרד החינוך',
        },
        actions: {
          match: 'קישור חשבון',
          submit: 'בואו נתחיל!',
        },
        validation: {
          username: 'יש למלא שם משתמש',
          password: 'יש למלא סיסמא',
        },
      },
      fields: {
        username: 'שם משתמש',
        password: 'סיסמה',
      },
      welcome: {
        caption:
          '“המורה החכם באמת אינו מציע לך להיכנס אל בית התבונה שלו, אלא במקום זאת מוביל אותך אל סף התודעה שלך”',
        heading: 'טוב לראות אותך!',
      },
      system: {
        name: 'סביבת הלמידה של אמית',
        roles: {
          students: 'כניסת תלמיד',
          console: 'כניסת מורה',
          builder: 'כניסת מורה',
        },
      },
      errors: {
        attempts: 'שימו לב, נשאר ניסיון אחרון | שימו לב, נשארו {n} ניסיונות',
        too_many:
          'נעשו יותר מדי ניסיונות התחברות שגויים, נא לנסות שוב מאוחר יותר',
        wrong: 'שם משתמש או סיסמא לא נכונים',
        generic:
          'יש שגיאה, אנא נסו שוב מאוחר יותר או צרו קשר עם התמיכה במידה והבעיה נמשכת.',
        not_allowed: 'שם משתמש זה הוא של תלמיד',
        recaptcha_web:
          'יש שגיאה, אנא נסו לרענן את העמוד או צרו קשר עם התמיכה במידה והבעיה נמשכת',
        recaptcha:
          'אימות נכשל. אנא נסו שוב מאוחר יותר או צרו קשר עם התמיכה אם הבעיה נמשכת.',
      },
    },
  },
};
