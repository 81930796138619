import type { Ref } from 'vue';
import { computed } from 'vue';
import type { ThemableChildrenApply } from '../types';
import { useThemable } from '../../../composables/useThemable';
import type { AMITTheme } from '@/components/utils/Themable/types';

type UseThemableChildReturns = {
  classes: Ref<string>;
};

type UseThemableChildProps = {
  apply: Ref<ThemableChildrenApply[]>;
  theme?: Ref<AMITTheme | undefined>;
};

export function useThemableChildClasses(
  props: UseThemableChildProps,
): UseThemableChildReturns {
  const {
    textClasses,
    borderClasses,
    backgroundClasses,
    hoverClasses,
    disabledClasses,
    focusClasses,
    isActive,
  } = useThemable(props.theme?.value);

  const classes = computed(() => {
    if (!isActive.value) return '';
    const _classes: string[] = [];
    if (props.apply.value.includes('text')) _classes.push(textClasses.value);
    if (props.apply.value.includes('border'))
      _classes.push(borderClasses.value);
    if (props.apply.value.includes('background'))
      _classes.push(backgroundClasses.value);
    if (props.apply.value.includes('hover')) _classes.push(hoverClasses.value);
    if (props.apply.value.includes('disabled'))
      _classes.push(disabledClasses.value);
    if (props.apply.value.includes('focus')) _classes.push(focusClasses.value);
    return _classes.join(' ');
  });

  return {
    classes,
  };
}
