<template>
  <FormItem :name :label :error-message="errorMessage" :extra>
    <InputText
      v-model="value"
      :aria-labelledby="name"
      :invalid="!!errorMessage"
      :disabled
      :placeholder/>
  </FormItem>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';

import InputText from 'primevue/inputtext';
import FormItem from './FormItem.vue';

type InputProps = {
  name: string;
  label: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  size?: 'large' | 'small';
  extra?: string;
};

const props = defineProps<InputProps>();
const { value, errorMessage } = useField<string>(() => props.name);
</script>
