<script lang="ts" setup>
import { onMounted, watch } from 'vue';

import { reactiveUploadResult, updateAndOpenInstance } from '@amit/composables';

import { imageFormats } from '@amit/foundation/utils';

const emit = defineEmits(['close']);
const props = defineProps({
  action: {
    type: Function,
    default: () => {},
  },
});

const openWidget = () => {
  updateAndOpenInstance({ clientAllowedFormats: imageFormats }, 'tiptap-image');
};

watch(
  () => reactiveUploadResult.value,
  () => {
    if (
      reactiveUploadResult.value.block === 'tiptap-image' &&
      reactiveUploadResult.value.type === 'success'
    ) {
      props.action(reactiveUploadResult.value.result);
      emit('close');
    } else if (reactiveUploadResult.value.type === 'abort') {
      emit('close');
    }
  },
  { deep: true },
);

onMounted(() => {
  //@ts-ignore
  openWidget('image');
});
</script>
