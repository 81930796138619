<script lang="ts">
import { defineComponent, onMounted, onUnmounted } from 'vue';
import { createToast, withProps } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import emitter from '@amit/composables/src/useBus';
import Notification from '/@/views/components/BaseNotification.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'NotificationToast',
  setup() {
    const router = useRouter();

    onMounted(() => {
      emitter.on('notification', (data: any) => {
        createToast(
          withProps(Notification, {
            notification: data,
            router,
          }),
          {
            hideProgressBar: true,
            position: 'top-right',
            timeout: 5000,
            type: data.type,
          },
        );
      });
    });

    onUnmounted(() => {
      emitter.off('notification');
    });

    return {};
  },
});
</script>
<template>
  <div></div>
</template>
