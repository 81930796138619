import axios from 'axios';
import { useFirebase, useAuth } from '@amit/auth';
import type { AxiosInstance } from 'axios';

const http: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE,
});

async function getToken() {
  const auth = useFirebase();
  return auth.currentUser?.getIdToken();
}

export const defineInterceptors = () => {
  http.interceptors.request.use(
    async config => {
      const token = await getToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  http.interceptors.response.use(
    response => response,
    async error => {
      if (error.config && error.response && error.response.status === 401) {
        const { logout } = useAuth();
        await logout();
      }

      return Promise.reject(error);
    },
  );
};
defineInterceptors();

export default http;
