<template>
  <ListGroup
    class="w-fit py-1 px-1"
    :class="{
      'items-center': alignmentCenter,
      'items-start': !alignmentCenter,
    }"
  >
    <ListGroupItem
      v-for="(option, key) in options"
      :key="key"
      :hover="false"
      :class="{
        '!border-0 pe-10 pointer-events-none !cursor-default': option.disabled,
        '!border-0 hover:bg-secondary-100': !option.disabled,
        'bg-primary-200': isSelected(option),
        '!p-2': !option.text,
      }"
      @click="ApplyAction(option)"
    >
      <component
        :is="option.icon"
        v-if="option.icon"
        :size="16"
        color="#000"
        stroke-width="1.2"
      />

      <p v-if="option.text" class="w-full" :class="option.classes ?? 'text-xs'">
        {{ option.text }}
      </p>
    </ListGroupItem>
  </ListGroup>
</template>
<script setup lang="ts">
import { inject, onMounted, PropType, ref } from 'vue';
import { useEditor } from '../composables/useEditor';
const { getSelected } = useEditor();
//@ts-ignore
import { ListGroup, ListGroupItem } from '@';

const props = defineProps({
  alignmentCenter: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: '',
  },
  options: {
    type: Array as PropType<Record<string, any>[]>,
    default: () => [],
  },
  action: {
    type: Function,
    default: () => {},
  },
  isActive: {
    type: Function,
    default: () => {},
  },
  modelValue: {
    type: Object as PropType<Record<string, any>>,
    default: () => ({}),
  },
});

const model = ref(props.modelValue);
const emit = defineEmits(['close']);

onMounted(() => {
  model.value = getSelected(inject('blockId'), props.type);
});

const isSelected = option => {
  if (typeof option.attributes != 'object') {
    return option.attributes == model.value;
  }
  return props?.isActive(option);
};

const ApplyAction = option => {
  props.action(option);
  emit('close');
};
</script>
