import { Table } from '@tiptap/extension-table';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import TableView from '../views/TableView.vue';

export default Table.extend({
  name: 'table',
  addOptions() {
    return {
      ...this.parent?.(),
      resizable: false,
      handleWidth: 5,
      cellMinWidth: 25,
      lastColumnResizable: false,
      allowTableNodeSelection: true,
    };
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      rows: {
        default: 3,
      },
      cols: {
        default: 3,
      },
      withHeaderRow: {
        default: true,
      },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(TableView);
  },
});
