<template>
  <div
    class="relative h-full"
    :class="{ [formInputClasses]: true, [identifier]: identifier }">
    <img v-if="block" class="block-img" :src="block" alt="" />
    <div class="form-item w-full h-full flex flex-col gap-4">
      <!--            <main_title>{{title}}</main_title>-->
      <!--            <sub_title>{{subTitle}}</sub_title>-->
      <h5
        v-if="title"
        :class="{
          'truncate whitespace-nowrap': truncated,
          'whitespace-pre-line': !truncated,
        }"
        :title="title">
        {{ title }}
      </h5>
      <p
        v-if="subTitle"
        :class="{
          'truncate whitespace-nowrap': truncated,
          'whitespace-pre-line': !truncated,
        }"
        :title="subTitle">
        {{ subTitle }}
      </p>
      <slot name="subTitle"></slot>
      <label v-if="label" :class="labelClasses">
        {{ label }}
        <span v-if="required" class="px-0.5 text-red-500">*</span>
      </label>
      <slot />
      <p v-if="error && errorMessage" class="text-sm text-red-500">
        {{ errorMessage }}
      </p>
      <div v-if="$slots.tip">
        <slot name="tip" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useFormControl } from './composables/useFormControl';

defineProps({
  title: {
    type: String,
    default: '',
  },
  subTitle: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: null,
  },
  block: {
    type: String,
    default: null,
  },
  identifier: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  error: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: null,
  },
  required: {
    type: Boolean,
    default: false,
  },
  truncated: {
    type: Boolean,
    default: false,
  },
});

const { labelClasses, formInputClasses } = useFormControl('blue');
</script>
