<template>
  <themable-child
    tag="button"
    class="flex align-items gap-1"
    :apply="appliableTheme"
    :class="wrapperClasses"
    :disabled="disabled || loading">
    <div v-if="!isOutlineGradient && ($slots.prefix || loadingPrefix)">
      <!--automatically add mr class if slot provided or loading -->
      <spinner v-if="loadingPrefix" :color="spinnerColor" :size="spinnerSize" />
      <slot v-else name="prefix" />
    </div>

    <div v-if="isOutlineGradient && ($slots.prefix || loadingPrefix)">
      <!--if outline gradient - need to place slots inside span -->
      <spinner v-if="loadingPrefix" :color="spinnerColor" :size="spinnerSize" />
      <slot v-else name="prefix" />
    </div>

    <slot />

    <div v-if="isOutlineGradient && ($slots.suffix || loadingSuffix)">
      <!--if outline gradient - need to place slots inside span -->
      <spinner v-if="loadingSuffix" :color="spinnerColor" :size="spinnerSize" />
      <slot v-else name="suffix" />
    </div>

    <div v-if="!isOutlineGradient && ($slots.suffix || loadingSuffix)">
      <!--automatically add ml class if slot provided or loading -->
      <spinner v-if="loadingSuffix" :color="spinnerColor" :size="spinnerSize" />
      <slot v-else name="suffix" />
    </div>
  </themable-child>
</template>
<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import type { PropType } from 'vue';
import Spinner from '../Spinner/BaseSpinner.vue';
import { useButtonClasses } from './composables/useButtonClasses';
import { useButtonSpinner } from './composables/useButtonSpinner';
import ThemableChild from '@/components/utils/Themable/components/ThemableChild/ThemableChild.vue';

import type {
  ButtonGradient,
  ButtonMonochromeGradient,
  ButtonSize,
  ButtonVariant,
} from './types';
import type { ThemableChildrenApply } from '@/components/utils/Themable/components/ThemableChild/types';
const props = defineProps({
  color: {
    type: String as PropType<ButtonVariant>,
    default: 'default',
  },
  gradient: {
    type: [String, null] as PropType<ButtonGradient | null>,
    default: null,
  },
  size: {
    type: String as PropType<ButtonSize>,
    default: 'md',
  },
  shadow: {
    type: [String, null] as PropType<ButtonMonochromeGradient | '' | null>,
    default: null,
  },
  pill: {
    type: Boolean,
    default: false,
  },
  square: {
    type: Boolean,
    default: false,
  },
  outline: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  loadingPosition: {
    type: String as PropType<'suffix' | 'prefix'>,
    default: 'prefix',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const isOutlineGradient = computed(() => props.outline && props.gradient);

const loadingPrefix = computed(
  () => props.loading && props.loadingPosition === 'prefix',
);
const loadingSuffix = computed(
  () => props.loading && props.loadingPosition === 'suffix',
);

const { wrapperClasses } = useButtonClasses(toRefs(props));
const { color: spinnerColor, size: spinnerSize } = useButtonSpinner(
  toRefs(props),
);

const appliableTheme = computed<ThemableChildrenApply[]>(() => {
  if (['alternative', 'light'].includes(props.color)) return [];
  return ['background', 'hover', 'focus'];
});
</script>
