import axios, { AxiosResponse } from 'axios';
import { fetchWithRetries, getToken } from './utils';

let conversationId;

const botClient = axios.create({
  baseURL: 'https://chatwith.humains.com',
  headers: {
    'Content-Type': 'text/plain',
  },
});

export function useConversation() {
  const ask = async options => {
    let { body, humain } = options;

    const headers = {
      'CLIENT-ID': humain,
    };

    if (conversationId) {
      headers['Conversation-Id'] = conversationId;
    }

    const response: AxiosResponse<string> = await botClient.post('/bot', body, {
      headers,
    });

    if (!conversationId) {
      conversationId = response.headers['conversation-id'] || getToken();
    }

    return response.data;
  };

  const say = async options => {
    let { text = 'Heavy Metal', idle_url, azure_voice } = options;

    const DID_API = {
      key: 'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VybmFtZSI6ImRldmVsb3BlckBpbnByaXNsdGQuY29tIn0.krVDz6RLbUfhRl0lB0XS218mVq1K8PFvF0lwdoK0sPQ-jaMGpgUkk0MZ3OcYKcIK6xI4fa8pEkwlsXrQZFWJ1Q',
      url: 'https://api.exh.ai',
    };

    try {
      const talkResponse = await fetchWithRetries(
        `${DID_API.url}/animations/v3/generate_lipsync`,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${DID_API.key}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            text,
            idle_url,
            azure_voice,
          }),
        },
      );

      const videoBlob = await talkResponse.blob();
      return URL.createObjectURL(videoBlob);
    } catch (error) {
      console.log(error);
    }
  };

  return { ask, say };
}
