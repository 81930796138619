<template>
  <div ref="containerRef" class="w-full h-full">
    <TargetSlot ref="el" :style="style" />
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  h,
  inject,
  onBeforeUnmount,
  onMounted,
  ref,
  useAttrs,
  vShow,
  withDirectives,
} from 'vue';
import { PROVIDE_TELEPORT_TARGETS, TeleportTargets } from './const';
import {
  useDraggable,
  useElementBounding,
  useMutationObserver,
} from '@vueuse/core';
import { useReveal } from '@/apps/slideshow/useReveal';

defineOptions({
  inheritAttrs: false,
});

interface IProps {
  id: string;
  tag?: string;
  disabled?: boolean;
  temporary?: boolean;
  draggable?: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  tag: 'div',
  disabled: false,
  temporary: false,
  draggable: false,
});
const emit = defineEmits<{
  empty: [];
}>();
const targets = inject<TeleportTargets>(PROVIDE_TELEPORT_TARGETS);

if (!targets) {
  throw new Error(
    '[vue-safe-teleport] Teleport targets not provided, cannot use <TeleportTarget>.',
  );
}

const id = `#${props.id}`;
const disabled = computed(() => props.disabled);
const empty = ref(true);
const el = ref<HTMLElement | null>(null);
const style = ref('');
const { rtl } = useReveal();
const containerRef = ref(null);

const { height: containerHeight, width: containerWidth } = useElementBounding(
  containerRef,
  {
    reset: false,
  },
);

onMounted(() => {
  targets[id] = disabled as unknown as boolean;
  if (props.draggable) {
    const { style: _style } = useDraggable(el, {
      initialValue: {
        x: rtl.value ? 0 : ('100%' as unknown as number),
        y: '100%' as unknown as number,
      },
      capture: true,
      containerElement: containerRef.value,
      onMove: ({ x, y }) => {
        if (
          y < containerHeight.value - 100 &&
          x < containerWidth.value - 100 &&
          x > 0 &&
          y > 0
        ) {
          style.value = `${_style.value} bottom:auto`;
        }
      },
      onStart: (_, event) => {
        //@ts-ignore
        const hasClosestDraggableDataset =
          event.target?.closest('[data-draggable]')?.dataset?.draggable;
        const hasClosestFcEvent = (event.target as HTMLElement).closest(
          '.fc-event',
        );

        if (hasClosestDraggableDataset || hasClosestFcEvent) {
          return false;
        }
      },
    });
    style.value = _style.value;
  }
});

onBeforeUnmount(() => {
  delete targets[id];
});

const target = ref<HTMLElement | null>(null);
useMutationObserver(
  target,
  () => {
    const children = Array.from(target.value?.childNodes || []).filter(
      node => node.nodeType != 3,
    );

    empty.value = !target.value || children.length === 0;
    if (empty.value) {
      emit('empty');
    }

    if (children.length > 1) {
      const event = new Event('HideMe');
      children[0].dispatchEvent(event);
    }
  },
  { childList: true },
);

const attrs = useAttrs();
const TargetSlot = () =>
  withDirectives(h(props.tag, { id: props.id, ...attrs, ref: target }), [
    [vShow, props.temporary ? !empty.value : true],
  ]);
</script>
