import {
  mergeAttributes,
  Mark,
  markPasteRule,
  markInputRule,
} from '@tiptap/core';
import { generateObjectId } from '@/utils';

const processBlankMarks =
  (id: string, action: Function) =>
  ({ tr, editor }) => {
    let processed = false;
    const { doc } = editor.state;

    doc.nodesBetween(0, doc.content.size, (node, pos) => {
      if (node.marks) {
        node.marks.forEach(mark => {
          if (mark.type.name === 'blank' && mark.attrs.id === id) {
            const markStart = pos;
            const markEnd = pos + node.nodeSize;
            action(tr, markStart, markEnd, mark.type);
            processed = true;
          }
        });
      }
    });

    return processed ? tr : false;
  };

const ensureId = attributes => {
  if (!attributes) attributes = {};
  if (!attributes.id) attributes.id = generateObjectId();
  return attributes;
};

export interface BlankOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    blank: {
      /**
       * Set a blank mark
       * @example editor.commands.setBlank()
       */
      setBlank: () => ReturnType;
      /**
       * Toggle a blank mark
       * @example editor.commands.toggleBlank()
       */
      toggleBlank: () => ReturnType;
      /**
       * Unset a blank mark
       * @example editor.commands.unsetBlank()
       */
      unsetBlank: () => ReturnType;
      /**
       * Update a blank mark's text (matches.join(','))
       * @example editor.commands.updateBlank(id, matches)
       */
      updateBlank: () => ReturnType;
      /**
       * Remove a blank mark by ID
       * @example editor.commands.removeBlank(id)
       */
      removeBlank: () => ReturnType;
    };
  }
}

/**
 * Matches a blank to a {{blank}} on input.
 */
export const inputRegex =
  /(?:^|\s)(\{\{(?!\s+\{\{)((?:[^\}]+))\}\}(?!\s+\}\}))$/;

/**
 * Matches a blank to a {{blank}} on paste.
 */
export const pasteRegex =
  /(?:^|\s)(\{\{(?!\s+\{\{)((?:[^\}]+))\}\}(?!\s+\}\}))/g;

export default Mark.create<BlankOptions>({
  name: 'blank',

  priority: 1000,

  excludes: '_',

  addAttributes() {
    return {
      ...this.parent?.(),
      id: {
        default: generateObjectId(),
        renderHTML: attributes => {
          return {
            id: `${attributes.id}`,
          };
        },
      },
      class: {
        default: 'blank',
        renderHTML: attributes => {
          return {
            class: `${attributes.class}`,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'blank',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'blank',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
  //@ts-ignore
  addCommands() {
    return {
      setBlank:
        attributes =>
        ({ commands }) => {
          return commands.setMark(this.name, ensureId(attributes));
        },
      toggleBlank:
        attributes =>
        ({ commands }) => {
          return commands.toggleMark(this.name, ensureId(attributes));
        },
      unsetBlank:
        attributes =>
        ({ commands }) => {
          return commands.unsetMark(this.name, attributes);
        },

      updateBlank: (id, matches = []) =>
        processBlankMarks(id, (tr, markStart, markEnd) => {
          tr.insertText(matches.join(', '), markStart, markEnd);
        }),

      removeBlank: id =>
        processBlankMarks(id, (tr, markStart, markEnd, markType) => {
          tr.removeMark(markStart, markEnd, markType);
        }),
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Shift-b': () => this.editor.commands.toggleBlank(),
      Enter: ({ editor }) => {
        const { commands, view } = editor;
        const hasMark = commands.hasMark(this.name);

        if (hasMark) {
          commands.extendMarkRange(this.name);

          const { tr, selection } = editor.state;
          const { to } = selection;

          tr.setStoredMarks([]); // Exit the mark

          // Ensure there's a space after the mark
          if (tr.doc.textBetween(to, to + 1) === ' ') {
            commands.deleteRange({ from: to, to: to + 1 });
          }
          tr.insertText(' ', to);

          // Apply the transaction
          view.dispatch(tr);

          return true;
        }

        return false;
      },
    };
  },

  addInputRules() {
    return [
      markInputRule({
        find: inputRegex,
        type: this.type,
      }),
    ];
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: pasteRegex,
        type: this.type,
      }),
    ];
  },
});
