<template>
  <div
    class="bg-white flex flex-col items-center justify-start no-scrollbar h-14 shadow-b shadow-dropdown-light">
    <div
      class="container h-full w-full z-[30] flex items-center justify-between space-header">
      <div class="flex space-x-2 space-x-reverse items-center">
        <MiniLogo
          class="cursor-pointer"
          @click="$router.push({ name: 'home' })"/>
        <div class="text-lg font-anomaliaBold">{{ userSchool }}</div>
      </div>
      <div class="flex space-x-5 space-x-reverse items-center">
        <BaseDropdown placement="right">
          <template #trigger>
            <LetterAvatar :user-name="userName" />
          </template>
          <ListGroup>
            <ListGroupItem @click="$router.push({ name: 'home' })">
              <div class="text-center">מרחב אישי</div>
            </ListGroupItem>
            <ListGroupItem @click="logout">
              <div class="text-center">התנתקות</div>
            </ListGroupItem>
          </ListGroup>
        </BaseDropdown>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { get } from 'lodash';
import { useAuth } from '@amit/auth';
import { useRouter } from 'vue-router';
import { useAccountStore } from '/@/app/store/account';
import { BaseDropdown, ListGroup, ListGroupItem } from '../../../../../src';
import MiniLogo from '/@/assets/miniLogo.svg?component';
import LetterAvatar from '/@/views/components/LetterAvatar.vue';

const accountStore = useAccountStore();
const router = useRouter();
const userSchool = computed(() => get(accountStore, 'user.school.name', ''));
const userName = computed(() => get(accountStore, 'user.fullName', ''));

//TODO: ephemeral...
const { logout } = useAuth();
</script>
