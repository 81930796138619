<script setup lang="ts">
import { PropType, defineAsyncComponent } from 'vue';

const ExclamationCircleComponent = defineAsyncComponent(
  () => import('/@/assets/exclamation_circle.svg?component'),
);

export interface INotification {
  id?: string;
  content: string;
  title: string;
  type: string;
  notifiable_type: string;
  level: string;
  created_on: string;
  metadata: {
    lesson: string;
    exercise: string;
  };
  read_at: string;
}

defineProps({
  notification: {
    type: Object as PropType<INotification>,
    required: true,
  },
});
</script>

<template>
  <div class="w-full">
    <div class="mx-3 flex flex-col w-full">
      <div class="flex">
        <ExclamationCircleComponent :class="notification.level + '-toast'" />
        <span class="font-semibold text-dark pr-2">
          {{ notification.title }}
        </span>
      </div>
      <div class="font-semibold text-medium text-sm pr-7">
        {{ notification.content }}
      </div>
    </div>
  </div>
</template>

<style>
.info-toast path {
  fill: #6366f1;
}

.warning-toast path {
  fill: #f59e0b;
}

.danger-toast path {
  fill: #f43f5e;
}

.success-toast path {
  fill: #10b981;
}

.default-toast path {
  fill: #94a3b8;
}
</style>
