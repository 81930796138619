import { Extension, CommandProps } from '@tiptap/core';
import { getNextNode, getParentTopLevel } from '../composables/utils';
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    generalExtension: {
      addSpaceBefore: () => ReturnType;
      addSpaceAfter: () => ReturnType;
      removeMarks: (marks) => ReturnType;
      hasMark: (markName: string) => ReturnType;
    };
  }
}

export default Extension.create({
  name: 'generalExtension',
  //@ts-ignore
  addCommands() {
    return {
      addSpaceBefore:
        always =>
        ({ state, dispatch }) => {
          const { tr, schema } = state;
          const { $from } = state.selection;
          const isAtBeginning = $from.pos === 1;

          const insertParagraph = position => {
            const paragraph = schema.nodes.paragraph.create({ class: 'body' });
            tr.insert(position, paragraph);
            if (dispatch) dispatch(tr);
          };

          if (always) {
            insertParagraph($from.pos - 1);
            return true;
          }

          if (isAtBeginning) {
            const firstNode = state.doc.firstChild;
            const isFirstNodeEmpty = firstNode && firstNode.content.size === 0;

            if (isFirstNodeEmpty) {
              insertParagraph($from.pos - 1);
              return true;
            }
            return false;
          }

          const { topLevelPos, topLevelNode } = getParentTopLevel($from);

          if (topLevelPos > 0) {
            const previousPosition = topLevelPos - 1;
            const previousNode = state.doc.nodeAt(previousPosition);
            const isBeforeHorizontalRule =
              previousNode &&
              previousNode.type.name === 'horizontalRule' &&
              topLevelNode.content.size <= 1;

            if (isBeforeHorizontalRule) {
              insertParagraph($from.pos - 1);
              return true;
            }
          }
          return false;
        },

      addSpaceAfter:
        (always = false) =>
        ({ state, dispatch }: CommandProps) => {
          const { tr, schema } = state;
          const { $from } = state.selection;
          const paragraph = schema.nodes.paragraph.create({ class: 'body' });
          let endPosition = $from.pos + $from.node().nodeSize + 1;
          const { nextNode } = getNextNode(tr, $from.pos);

          if (
            nextNode?.type?.name == 'horizontalRule' ||
            nextNode?.childCount === 0 ||
            always
          ) {
            tr.insert(endPosition, paragraph);
          }

          if (dispatch) {
            dispatch(tr);
          }
          return true;
        },

      removeMarks:
        marks =>
        ({ state, dispatch }: CommandProps) => {
          const { tr, selection, schema } = state;
          const { $from } = selection;
          const node = $from.node();
          let modified = false;
          const nodeStart = $from.before();
          const nodeEnd = nodeStart + node.nodeSize;
          marks.forEach(mark => {
            tr.removeMark(nodeStart, nodeEnd, schema.marks[mark]);
            modified = true;
          });

          if (modified && dispatch) {
            dispatch(tr);
          }

          return modified;
        },

      hasMark:
        (markName: string) =>
        ({ state }: CommandProps) => {
          //@ts-ignore
          const { from, to, $cursor } = state.selection;

          if ($cursor) {
            return $cursor.marks().some(mark => mark.type.name === markName);
          }

          return state.doc.rangeHasMark(from, to, state.schema.marks[markName]);
        },
    };
  },
});
