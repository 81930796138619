export const imageFormats = ['image/jpeg', 'image/png', 'image/gif'];
export const videoFormats = [
  // 'video/x-msvideo', // .avi
  'video/quicktime', // .mov
  // 'video/x-ms-wmv', // .wmv
  // 'video/x-flv', // .flv
  // 'video/mpeg', // .mpg
  // 'video/webm', // .webm
  'video/mp4',
];
