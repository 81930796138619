<template>
  <component :is="tag" :class="simplifyTailwindClasses(classAttr, classes)">
    <slot />
  </component>
</template>
<script lang="ts" setup>
import type { PropType } from 'vue';
import { useThemableChildClasses } from './composables/useThemableChildClasses';
import type { ThemableChildrenApply } from '@/components/utils/Themable/components/ThemableChild/types';
import { computed, toRefs, useAttrs } from 'vue';
import { simplifyTailwindClasses } from '@/utils/simplifyTailwindClasses';
import type { AMITTheme } from '@/components/utils/Themable/types';

const attrs = useAttrs();

const props = defineProps({
  apply: {
    type: Array as PropType<ThemableChildrenApply[]>,
    required: true,
  },
  tag: {
    type: String,
    default: 'div',
  },
  theme: {
    type: String as PropType<AMITTheme>,
    default: undefined,
  },
});

const { classes } = useThemableChildClasses(toRefs(props));

const classAttr = computed<string>(() => {
  return (attrs.class as string) || ''; // TODO:
});
</script>
