<template>
  <aside
    ref="sidebar"
    :class="
      sidebarVisible ? 'translate-x-0' : 'translate-x-full md:translate-x-0'
    "
    class="transition-transform bg-white border-l border-secondary-250 w-70 h-full fixed md:relative z-40 md:z-auto flex-shrink-0">
    <div class="h-full flex flex-col">
      <div
        class="mx-4 px-1 flex-1 pt-4 pb-6 bg-white flex flex-col gap-2 h-full overflow-y-auto">
        <template v-for="(element, eIndex) in menuItems" :key="eIndex">
          <div
            v-if="!element.role || user.role?.includes(element.role)"
            :id="element.id ?? `main-link-${eIndex}`"
            class="rounded-md active-background px-3 py-2 flex items-center cursor-pointer transition-colors ease-in-out"
            :class="{
              'bg-[#E0F2FE]': element.path === $route.name,
              'hover:bg-secondary-200': element.path !== $route.name,
            }"
            @click="element.path && $router.push({ name: element.path })">
            <div class="flex w-full justify-between items-center">
              <div class="w-full flex items-center gap-3 text-secondary-900">
                <LucideIcon
                  :name="element.iconName"
                  :size="20"
                  :stroke-width="2"/>
                <span class="w-full text-sm">{{ element.title }}</span>
              </div>
            </div>
          </div>
        </template>

        <div
          class="flex-1 mt-4 border-t pl-1 pt-1 border-secondary-250 overflow-y-auto">
          <div
            v-if="
              secondaryNavigation.length > 0 &&
              secondaryNavigation.every(item => !item.subLinks)
            "
            class="pt-3 sticky top-0 bg-white">
            <SearchInput
              v-model="search"
              placeholder="חיפוש"
              :style="'neutral'"
              full
              @reset-search="search = ''"/>
          </div>
          <ul class="mt-3 flex flex-col px-2 gap-2">
            <li v-if="showNoResultsMessage">אין תוצאות</li>
            <template
              v-for="(parentLink, parentIndex) in filter(
                sortedSecondaryNavigation,
                'text',
                search,
              )"
              :key="parentIndex">
              <SidebarLinkGroup
                v-if="parentLink.subLinks"
                v-slot="parentLinkSlot">
                <label
                  class="block truncate transition duration-150 cursor-pointer select-none"
                  @click.prevent="parentLinkSlot.handleClick()">
                  <span class="flex items-center justify-between">
                    <span class="ml-3 text-sm">
                      {{ parentLink.text }}
                    </span>
                    <svg
                      class="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 transition-transform"
                      :class="parentLinkSlot.expanded && 'rotate-180'"
                      viewBox="0 0 12 12">
                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                  </span>
                </label>
                <ul
                  class="mt-1 pt-1 transition-all flex flex-col gap-1"
                  :class="!parentLinkSlot.expanded && 'hidden'">
                  <RouterLink
                    v-for="link of parentLink.subLinks"
                    :key="link.to"
                    v-slot="{ href, navigate, isActive }"
                    :to="link.to"
                    custom>
                    <li
                      class="w-full px-3 py-2 rounded-md active-background flex items-center transition-colors ease-in-out hover:bg-primary-200"
                      :class="
                        isActive && 'bg-primary-200 ring-1 ring-secondary-300'
                      ">
                      <a
                        class="flex duration-150 truncate text-sm w-full h-full gap-4"
                        :class="isActive ? 'cursor-default' : ''"
                        :href="href"
                        @click="navigate">
                        <span class="">
                          {{ link.text }}
                        </span>
                        <span :title="link.groupName" class="truncate">
                          {{ link.groupName }}
                        </span>
                      </a>
                    </li>
                  </RouterLink>
                </ul>
              </SidebarLinkGroup>
              <RouterLink
                v-else
                :key="parentLink.to"
                v-slot="{ href, navigate, isActive }"
                :to="parentLink.to"
                custom>
                <li
                  class="px-3 py-2 rounded-md active-background flex items-center transition-colors ease-in-out"
                  :class="
                    isActive
                      ? 'bg-[#E0F2FE]'
                      : 'cursor-pointer hover:bg-secondary-200'
                  "
                  @click="navigate">
                  <a
                    class="block duration-150 truncate text-sm"
                    :class="isActive ? 'cursor-default' : ''"
                    :href="href">
                    <span class="">
                      {{ parentLink.text }}
                    </span>
                  </a>
                </li>
              </RouterLink>
            </template>
          </ul>
        </div>
      </div>
      <div class="flex-none p-4 bg-secondary-75 mx-10 mb-5 rounded-md relative">
        <div class="flex flex-col items-center justify-end text-sm">
          <img :src="Logo" alt="logo" class="" />
          <div class="pt-2 w-full text-center">
            <span v-if="school" class="font-anomaliaBold block">
              {{ school.name }}
            </span>
            <span v-if="system" class="font-anomaliaBold block">
              {{ hebrewYears[system.currentYear] }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </aside>
</template>

<script setup lang="ts">
import { LucideIcon } from '../../../../src';
// import Rocket from '../assets/rocket.svg?url';
import Logo from '../assets/logo-new.png';
import { hebrewYears } from '../utils';
import { SearchInput } from '@amit/foundation';
import SidebarLinkGroup from './SidebarLinkGroup.vue';
import { useNavigation, usePrimaryLinks } from '../useLayout';
import { ref, computed } from 'vue';

defineProps({
  user: {
    type: Object,
    default: () => {},
  },
  school: {
    type: Object,
    default: () => {},
  },
  system: {
    type: Object,
    default: () => {},
  },
  sidebarVisible: {
    type: Boolean,
    default: false,
  },
});

const menuItems = usePrimaryLinks();

const search = ref('');
const secondaryNavigation = useNavigation();

const filter = (array, key, value) => {
  return array?.filter(object => {
    return object[key].toLowerCase().includes(value.toLowerCase());
  });
};

const showNoResultsMessage = computed(() => {
  const filteredResults = filter(
    secondaryNavigation.value,
    'text',
    search.value,
  );

  return (
    filteredResults.length === 0 &&
    secondaryNavigation.value.length > 0 &&
    secondaryNavigation.value.every(item => !item.subLinks)
  );
});

const sortedSecondaryNavigation = computed(() => {
  return secondaryNavigation.value.map(parentLink => {
    if (!parentLink.subLinks) return parentLink;

    const sortedSubLinks = [...parentLink.subLinks].sort((a, b) => {
      const textA = a.text || '';
      const textB = b.text || '';
      return textA.localeCompare(textB);
    });

    return { ...parentLink, subLinks: sortedSubLinks };
  });
});
</script>

<style scoped></style>
