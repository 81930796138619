import { VueNodeViewRenderer } from '@tiptap/vue-3';
import TiptapImage from '@tiptap/extension-image';
import ImageView from '../views/ImageView.vue';
import { NodeSelection } from '@tiptap/pm/state';
import { Command } from '@tiptap/core';
import {
  isFirstNode,
  isLastNode,
  getPreviousNode,
  getNextNode,
} from '../composables/utils';
export const enum ImageDisplay {
  INLINE = 'inline',
  BREAK_TEXT = 'block',
  FLOAT_LEFT = 'left',
  FLOAT_RIGHT = 'right',
}
export const DEFAULT_IMAGE_WIDTH = 450;

export const DEFAULT_IMAGE_DISPLAY = ImageDisplay.BREAK_TEXT;

export default TiptapImage.extend({
  inline() {
    return true;
  },

  group() {
    return 'inline';
  },

  defining: true,
  selectable: true,

  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: DEFAULT_IMAGE_WIDTH,
        parseHTML: element => {
          const width =
            element.style.width || element.getAttribute('width') || null;
          return width == null ? null : parseInt(width, 10);
        },
        renderHTML: attributes => {
          return {
            width: attributes.width,
          };
        },
      },
      height: {
        default: null,
        parseHTML: element => {
          const height =
            element.style.height || element.getAttribute('height') || null;
          return height == null ? null : parseInt(height, 10);
        },
        renderHTML: attributes => {
          return {
            height: attributes.height,
          };
        },
      },
      display: {
        default: DEFAULT_IMAGE_DISPLAY,
        parseHTML: element => {
          const { cssFloat, display } = element.style;
          let dp =
            element.getAttribute('data-display') ||
            element.getAttribute('display');
          if (dp) {
            dp = /(inline|block|left|right)/.test(dp)
              ? dp
              : ImageDisplay.BREAK_TEXT;
          } else if (cssFloat === 'left' && !display) {
            dp = ImageDisplay.FLOAT_LEFT;
          } else if (cssFloat === 'right' && !display) {
            dp = ImageDisplay.FLOAT_RIGHT;
          } else if (!cssFloat && display === 'inline') {
            dp = ImageDisplay.INLINE;
          } else {
            dp = ImageDisplay.BREAK_TEXT;
          }

          return dp;
        },
        renderHTML: attributes => {
          return {
            ['data-display']: attributes.display,
          };
        },
      },
    };
  },

  addOptions() {
    return {
      ...this.parent?.(),
      inline: true,
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(ImageView);
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]',
      },
    ];
  },
  //@ts-ignore
  addCommands() {
    return {
      canMoveUp:
        (): Command =>
        ({ state }) => {
          const { $from } = state.selection;
          return !isFirstNode($from);
        },
      canMoveDown:
        (): Command =>
        ({ state }) => {
          const { tr } = state;
          const { $from } = state.selection;
          return !isLastNode($from, tr);
        },
      moveImageUp:
        (): Command =>
        ({ state, dispatch }) => {
          const { selection, tr } = state;
          const { $from } = selection;

          const imageNode = $from.nodeAfter;
          const imagePos = $from.pos;
          //@ts-ignore
          const { prevNodePos } = getPreviousNode(tr, imagePos);

          let insertNodePos = Math.max(prevNodePos - 1, 0);
          //@ts-ignore
          tr.delete(imagePos, imagePos + imageNode.nodeSize);
          //@ts-ignore
          tr.insert(insertNodePos, imageNode);

          const newSelection = NodeSelection.create(tr.doc, insertNodePos);
          tr.setSelection(newSelection);
          //
          if (dispatch) dispatch(tr);
          return true;
        },
      moveImageDown:
        (): Command =>
        ({ state, dispatch }) => {
          const { selection, tr } = state;
          const { $from } = selection;

          const imageNode = $from.nodeAfter;
          const imagePos = $from.pos;
          //@ts-ignore
          const { nextNode, nextNodePos } = getNextNode(tr, imagePos, 0);

          let insertNodePos = Math.min(
            nextNodePos + nextNode.nodeSize + 1,
            tr.doc.content.size - 1,
          );
          //@ts-ignore
          tr.delete(imagePos, imagePos + imageNode.nodeSize);
          //@ts-ignore
          tr.insert(insertNodePos, imageNode);

          const newSelection = NodeSelection.create(tr.doc, insertNodePos);
          tr.setSelection(newSelection);
          //
          if (dispatch) dispatch(tr);
          return true;
        },
    };
  },
});
