<template>
  <ListGroup
    ref="target"
    class="w-fit max-w-80 border border-secondary-250 shadow-sm"
  >
    <div class="table-grid-size-editor">
      <div class="table-grid-size-editor__body">
        <div
          v-for="row in tableGridSize.row"
          :key="'r' + row"
          class="table-grid-size-editor__row"
        >
          <div
            v-for="col in tableGridSize.col"
            :key="'c' + col"
            :class="{
              'table-grid-size-editor__cell--selected':
                col <= selectedTableGridSize.col &&
                row <= selectedTableGridSize.row,
            }"
            class="table-grid-size-editor__cell"
            @mouseover="selectTableGridSize(row, col)"
            @mousedown="confirmCreateTable(row, col)"
          >
            <div class="table-grid-size-editor__cell__inner" />
          </div>
        </div>
      </div>

      <div class="table-grid-size-editor__footer">
        {{ `${selectedTableGridSize.row} (X) ${selectedTableGridSize.col}` }}
      </div>
    </div>
  </ListGroup>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
//@ts-ignore
import { ListGroup } from '@';

const INIT_GRID_SIZE = 5;
const MAX_GRID_SIZE = 8;
const DEFAULT_SELECTED_GRID_SIZE = 2;

interface GridSize {
  row: number;
  col: number;
}

const emit = defineEmits(['action', 'close']);

const tableGridSize = ref<GridSize>({
  row: INIT_GRID_SIZE,
  col: INIT_GRID_SIZE,
});

const selectedTableGridSize = ref<GridSize>({
  row: DEFAULT_SELECTED_GRID_SIZE,
  col: DEFAULT_SELECTED_GRID_SIZE,
});

const confirmCreateTable = (rows: number, cols: number) => {
  emit('action', { rows, cols, withHeaderRow: true });
  emit('close');
};

const selectTableGridSize = (row: number, col: number): void => {
  if (row === tableGridSize.value.row) {
    tableGridSize.value.row = Math.min(row + 1, MAX_GRID_SIZE);
  }

  if (col === tableGridSize.value.col) {
    tableGridSize.value.col = Math.min(col + 1, MAX_GRID_SIZE);
  }

  selectedTableGridSize.value.row = row;
  selectedTableGridSize.value.col = col;
};

const target = ref();
onClickOutside(target, () => emit('close'));
</script>

<style>
.table-grid-size-editor {
  @apply flex flex-col flex-wrap justify-between bg-white p-1;
}

.table-grid-size-editor__row {
  @apply flex;
}

.table-grid-size-editor__cell {
  @apply p-1; /* Tailwind doesn't have padding units in px like 5, so you need to use rem units, which are `p-1.25` for 5px */
}

.table-grid-size-editor__cell__inner {
  @apply border border-secondary-300 box-border rounded-sm h-4 w-4 p-0.5; /* Use equivalent Tailwind classes */
}

.table-grid-size-editor__cell--selected .table-grid-size-editor__cell__inner {
  @apply bg-primary-200 border-primary;
}
.table-grid-size-editor__cell--selected
  .table-grid-size-editor__cell__inner:hover {
  @apply bg-primary border-primary;
}

.table-grid-size-editor__footer {
  @apply mt-1 flex items-center justify-center; /* `mt-1.25` for 5px margin-top */
}
</style>
