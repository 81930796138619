import { Node, mergeAttributes } from '@tiptap/core';

export default Node.create({
  name: 'backgroundColor',

  group: 'block',

  content: 'block*',

  parseHTML() {
    return [
      {
        tag: 'div[data-background]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes({ 'data-background': '' }, HTMLAttributes),
      0,
    ];
  },

  addAttributes() {
    return {
      color: {
        default: null,
        // Take the attribute values
        renderHTML: attributes => {
          // … and return an object with HTML attributes.
          return {
            style: ` background-color : ${attributes.color}`,
            class: 'p-2',
          };
        },
      },
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            default: null,
            parseHTML: element => element.style['background-color'] || '',
            renderHTML: attributes => {
              if (!attributes.color) {
                return {};
              }

              return {
                style: ` background-color : ${attributes.color}`,
              };
            },
          },
        },
      },
    ];
  },
  //@ts-ignore
  addCommands() {
    return {
      setBackgroundColor:
        attributes =>
        ({ commands, state }) => {
          const { from, to } = state.selection;

          state.doc.nodesBetween(from, to, node => {
            if (
              node.type.name == 'bulletList' ||
              node.type.name == 'orderedList'
            ) {
              commands.updateAttributes(node.type.name, attributes);
            }
          });
          return commands.wrapIn(this.name, attributes);
        },
      unsetBackgroundColor:
        () =>
        ({ commands }) => {
          return commands.lift(this.name);
        },
    };
  },
  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        //@ts-ignore
        const { state, tr } = editor;
        const { selection } = state;
        const { $from } = selection;
        const pos = $from.before($from.depth);

        if ($from.parent.type.name === this.name) {
          tr.setNodeMarkup(pos, undefined, { ...$from.parent.attrs });
          editor.view.dispatch(tr);
          return true;
        }

        return false;
      },
    };
  },
});
