import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

export const listSchools = (params = {}) =>
  useApi(http.get('/schools', { params }));

export const loadSchool = (schoolId: string) =>
  useApi(http.get(`/schools/${schoolId}`));

export const syncSchool = (schoolId: string) =>
  useApi(http.post(`/schools/${schoolId}/sync`));

export const listSchoolUsers = (schoolId: string) =>
  useApi(http.get('/users', { params: { school: schoolId } }));

export const createUser = data => useApi(http.post('/users', data));

export const updateUser = data => useApi(http.put(`/users/${data.id}`, data));

export const listSchoolGroups = (schoolId: string) =>
  useApi(
    http.get('/groups', {
      params: { school_id: schoolId, populate: 'students,teachers,courses' },
    }),
  );

export const createGroup = data => useApi(http.post('/groups', data));
export const editGroupName = (groupId, groupName, sis_id) =>
  useApi(http.put(`/groups/${groupId}`, { name: groupName, sis_id }));
export const editGroupAddMembers = (groupId, membersToAdd) =>
  useApi(http.post(`/groups/${groupId}/members`, membersToAdd));
export const editGroupRemoveMembers = (groupId, membersToRemove) =>
  useApi(
    http.delete(`/groups/${groupId}/members`, { params: membersToRemove }),
  );

export const syncGroup = group => useApi(http.post(`/groups/${group}/sync`));

export const searchSIS = (schoolId: string, search: string) =>
  useApi(http.get('/groups/sis', { params: { school: schoolId, search } }));
