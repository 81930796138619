import OrderedList from '@tiptap/extension-ordered-list';
import { toggleList } from '../composables/utils';

export default OrderedList.extend({
  name: 'orderedList',
  addAttributes() {
    return {
      ...this.parent?.(),
      textAlign: {
        default: 'inherit',
        parseHTML: element => element.style.textAlign || 'inherit',
        renderHTML: attributes => {
          if (!attributes.textAlign) {
            return {};
          }

          return {
            style: `text-align: ${attributes.textAlign};`,
          };
        },
      },
    };
  },
  addCommands() {
    return {
      ...this.parent?.(),
      toggleOrderedList: () => params => {
        return toggleList(params, this.name, this.options);
      },
    };
  },
});
