import { computed, nextTick, ref, watch } from 'vue';
import type { Ref } from 'vue';
import classNames from 'classnames';
import type { DropdownPlacement, AlignPlacement } from '../types';
import { useElementBounding } from '@vueuse/core';

const defaultGapInPx = 8;

const placementDropdownClasses: Record<DropdownPlacement, string> = {
  bottom: '',
  left: '',
  right: '',
  top: 'left-0',
};

export type UseDropdownClassesProps = {
  placement: Ref<DropdownPlacement>;
  align: Ref<AlignPlacement>;
  contentRef: Ref<HTMLDivElement | undefined>;
  triggerRef: Ref<HTMLDivElement | undefined>;
  visible: Ref<boolean>;
  fixed: Ref<boolean>;
};

export function useDropdownClasses(props: UseDropdownClassesProps): {
  contentClasses: Ref<string>;
  contentStyles: Ref<string>;
} {
  const triggerRect = ref(null as DOMRect | null);
  const contentRect = ref(null as DOMRect | null);

  watch(props.visible, visible => {
    if (visible) {
      nextTick(() => {
        triggerRect.value = useElementBounding(props.triggerRef);
        contentRect.value = useElementBounding(props.contentRef);
      });
    }
  });

  const defaultDropdownClasses = `${
    props.fixed.value ? 'fixed' : 'absolute'
  } z-[1302] bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700`;
  const dynamicClass = ref('');

  const contentClasses = computed(() => {
    return classNames(
      defaultDropdownClasses,
      placementDropdownClasses[props.placement.value],
      dynamicClass.value,
    );
  });

  const placementStyles = computed(() => {
    if (
      !props.visible.value ||
      !triggerRect?.value ||
      !contentRect?.value?.width
    )
      return '';
    const calc = placementCalculators[props.placement.value]();
    return calc;
  });

  // The position/alignment of the dropdown
  const alignPlacement: Record<AlignPlacement, () => string> = {
    left(): string {
      const left = triggerRect?.value?.left ?? 0;
      if (left + (contentRect?.value?.width ?? 0) > window.innerWidth) {
        return alignPlacement.right();
      }
      return `left: ${left}px;`;
    },
    right(): string {
      const left =
        (triggerRect?.value?.right ?? 0) - (contentRect?.value?.width ?? 0);
      if (left < 0) {
        return alignPlacement.left();
      }
      return `left: ${left}px;`;
    },
    top(): string {
      const top = triggerRect?.value?.top ?? 0;
      if (top + (contentRect?.value?.height ?? 0) > window.innerHeight) {
        return alignPlacement.bottom();
      }
      return `top: ${top}px;`;
    },
    bottom(): string {
      const top =
        (triggerRect?.value?.bottom ?? 0) - (contentRect?.value?.height ?? 0);
      if (top < 0) {
        return alignPlacement.top();
      }
      return `top: ${top}px;`;
    },
  };

  // The anchor point of the dropdown on the trigger
  const placementCalculators: Record<DropdownPlacement, () => string> = {
    bottom(): string {
      let numberOfTrys = 0;
      if (props.fixed.value) {
        const top = (triggerRect?.value?.bottom ?? 0) + defaultGapInPx;
        if (top + (contentRect?.value?.height ?? 0) > window.innerHeight) {
          if (props.placement.value == 'bottom') {
            numberOfTrys += 1;
          }
          if (numberOfTrys > 1) {
            numberOfTrys = 0;

            dynamicClass.value = ' h-[80%] overflow-auto';
            return `top: ${top}px; ${alignPlacement[
              props.align.value ?? 'right'
            ]()} `;
          }
          return placementCalculators.top();
        } else {
          return `top: ${top}px; ${alignPlacement[
            props.align.value ?? 'right'
          ]()}`;
        }
      } else {
        return `top: ${(triggerRect?.value?.height ?? 0) + defaultGapInPx}px;`;
      }
    },
    left(): string {
      if (props.fixed.value) {
        const left =
          (triggerRect?.value?.left ?? 0) -
          (contentRect?.value?.width ?? 0) -
          defaultGapInPx;
        if (left < 0) {
          return placementCalculators.right();
        } else {
          return `left: ${left}px; ${alignPlacement[
            props.align.value ?? 'top'
          ]()}`;
        }
      } else {
        return `right: ${(triggerRect?.value?.width ?? 0) + defaultGapInPx}px;`;
      }
    },
    right(): string {
      if (props.fixed.value) {
        const left = (triggerRect?.value?.right ?? 0) + defaultGapInPx;
        if (left + (contentRect?.value?.width ?? 0) > window.innerWidth) {
          return placementCalculators.left();
        } else {
          return `left: ${left}px; ${alignPlacement[
            props.align.value ?? 'top'
          ]()}`;
        }
      } else {
        return `left: ${(triggerRect?.value?.width ?? 0) + defaultGapInPx}px;`;
      }
    },
    top(): string {
      if (props.fixed.value) {
        const top =
          (triggerRect?.value?.top ?? 0) -
          (contentRect?.value?.height ?? 0) -
          defaultGapInPx;
        if (top < 0) {
          return placementCalculators.bottom();
        } else {
          return `top: ${top}px; ${alignPlacement[
            props.align.value ?? 'right'
          ]()}`;
        }
      } else {
        return `bottom: ${
          (triggerRect?.value?.height ?? 0) + defaultGapInPx
        }px;`;
      }
    },
  };

  return {
    contentClasses,
    contentStyles: placementStyles,
  };
}
