const maxRetryCount = 3;
const maxDelaySec = 4;

export const getRandomInt = max => {
  return Math.floor(Math.random() * Math.floor(max));
};

export const getToken = () => {
  const chars = '0123456789abcdef';
  let result = '';
  for (let i = 0; i < 32; i++) {
    result += chars[getRandomInt(16)];
  }
  return result;
};

export const fetchWithRetries = async (url, options, retries = 1) => {
  try {
    return await fetch(url, options);
  } catch (err) {
    if (retries <= maxRetryCount) {
      const delay =
        Math.min(Math.pow(2, retries) / 4 + Math.random(), maxDelaySec) * 1000;
      await new Promise(resolve => setTimeout(resolve, delay));
      console.log(
        `Request failed, retrying ${retries}/${maxRetryCount}. Error ${err}`,
      );
      return fetchWithRetries(url, options, retries + 1);
    } else {
      throw new Error(`Max retries exceeded. error: ${err}`);
    }
  }
};
