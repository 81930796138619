import type { Slots, VNode } from 'vue';
import { flatten } from './flatten';

// ref: https://github.com/TuSimple/naive-ui/blob/main/src/popover/src/Popover.tsx

export function getFirstSlotVNode(
  slots: Slots,
  slotName = 'default',
  props: unknown = undefined,
): VNode | null {
  const slot = slots[slotName];
  if (!slot) {
    return null;
  }
  const slotContent = flatten(slot(props));
  // vue will normalize the slot, so slot must be an array
  if (slotContent.length === 1) {
    return slotContent[0];
  } else {
    return null;
  }
}
