import { Command, Extension } from '@tiptap/core';
import { Transaction } from '@tiptap/pm/state';
export interface IndentOptions {
  types: string[];
  minLevel: number;
  maxLevel: number;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    indent: {
      indent: () => ReturnType;
      outdent: () => ReturnType;
    };
  }
}

export default Extension.create<IndentOptions>({
  name: 'indent',
  priority: 900,

  addOptions() {
    return {
      HTMLAttributes: {},
      types: ['orderList', 'bulletList', 'paragraph', 'heading'],
      exclude: ['table', 'tableCell', 'tableHeader', 'tableRow'],
      minLevel: 0,
      maxLevel: 8,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          indent: {
            renderHTML: attributes => {
              return attributes?.indent > this.options.minLevel
                ? {
                    'data-indent': attributes.indent,
                    style: `padding-inline-start: ${attributes.indent * 1}rem;`,
                  }
                : null;
            },
            parseHTML: element => {
              const level = Number(element.getAttribute('data-indent'));
              return level && level > this.options.minLevel ? level : 0;
            },
          },
        },
      },
    ];
  },

  addCommands() {
    const setNodeIndentMarkup = (
      tr: Transaction,
      pos: number,
      delta: number,
    ): Transaction => {
      const node = tr?.doc?.nodeAt(pos);
      if (node) {
        const nextLevel = (node.attrs.indent || 0) + delta;
        const { minLevel, maxLevel } = this.options;
        const indent =
          nextLevel < minLevel
            ? minLevel
            : nextLevel > maxLevel
              ? maxLevel
              : nextLevel;
        if (indent !== node.attrs.indent) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { indent: oldIndent, ...currentAttrs } = node.attrs;
          const nodeAttrs =
            indent > minLevel ? { ...currentAttrs, indent } : currentAttrs;
          return tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks);
        }
      }
      return tr;
    };

    const updateIndentLevel = (tr: Transaction, delta: number): Transaction => {
      const { doc, selection } = tr;
      if (doc && selection) {
        const { from, to } = selection;
        doc.nodesBetween(from, to, (node, pos, parent) => {
          const isExcluded =
            //@ts-ignore
            this.options.exclude.includes(node.type.name) ||
            //@ts-ignore
            (parent && this.options.exclude.includes(parent.type.name));

          if (this.options.types.includes(node.type.name) && !isExcluded) {
            tr = setNodeIndentMarkup(tr, pos, delta);
            return false;
          }

          return true;
        });
      }

      return tr;
    };
    const applyIndent: (direction: number) => () => Command =
      direction =>
      () =>
      ({ tr, state, dispatch }) => {
        const { selection } = state;
        tr = tr.setSelection(selection);
        tr = updateIndentLevel(tr, direction);
        if (tr.docChanged) {
          dispatch?.(tr);
          return true;
        }

        return false;
      };

    return {
      indent: applyIndent(1),
      outdent: applyIndent(-1),
    };
  },

  addKeyboardShortcuts() {
    return {
      Tab: () => {
        return this.editor.commands.indent();
      },
      'Shift-Tab': () => {
        return this.editor.commands.outdent();
      },
    };
  },
});
