import { Node, mergeAttributes } from '@tiptap/core';

export default Node.create({
  name: 'direction',

  group: 'block',

  content: 'block*',

  parseHTML() {
    return [
      {
        tag: 'div[dir]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes({ dir: '' }, HTMLAttributes), 0];
  },

  addOptions() {
    return {
      types: ['bulletList', 'orderedList', 'paragraph', 'heading'], // Default types to apply font size to
    };
  },

  addAttributes() {
    return {
      dir: {
        default: 'rtl',
        // Take the attribute values
        renderHTML: attributes => {
          return {
            dir: `${attributes.dir}`,
          };
        },
      },
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          dir: {
            default: null,
            //@ts-ignore
            parseHTML: element => element.style.dir || '',
            renderHTML: attributes => {
              if (!attributes.dir) {
                return {};
              }

              return {
                dir: `${attributes.dir}`,
              };
            },
          },
        },
      },
    ];
  },
  //@ts-ignore
  addCommands() {
    return {
      setDirection:
        dir =>
        ({ commands, state }) => {
          const { from, to } = state.selection;
          commands.setTextAlign(dir == 'rtl' ? 'right' : 'left');

          state.doc.nodesBetween(from, to, node => {
            if (
              node.type.name == 'bulletList' ||
              node.type.name == 'orderedList'
            ) {
              commands.updateAttributes(node.type.name, { dir });
            }
          });
          return commands.wrapIn(this.name, { dir });
        },
    };
  },
});
