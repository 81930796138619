import Document from '@tiptap/extension-document';
import Heading from '../nodes/Heading';
import Text from '@tiptap/extension-text';
import Link from '@tiptap/extension-link';
import Paragraph from '../nodes/Paragraph';
import Tooltip from '../nodes/Tooltip';
import Blank from '../nodes/Blank';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import { Underline } from '@tiptap/extension-underline';
import Highlight from '@tiptap/extension-highlight';
import BulletList from '../nodes/BulletList';
import OrderedList from '../nodes/OrderedList';
import ListItem from '../nodes/listItem';
import TextAlign from '@tiptap/extension-text-align';
import Table from '../nodes/Table';
import TableCell from '../nodes/TableCell';
import TableHeader from '../nodes/TableHeader';
import TableRow from '@tiptap/extension-table-row';
import { Color } from '@tiptap/extension-color';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import { TextStyle } from '@tiptap/extension-text-style';
import { Placeholder } from '@tiptap/extension-placeholder';
import GeneralExtension from '../nodes/GeneralExtensions';
import GeneralKeyboardExtensions from '../nodes/GeneralKeyboardExtensions';
import Indent from '../nodes/Indent';
import HardBreak from '../nodes/HardBreak';
import TextDirection from '../nodes/TextDirection';
import LineHeight from '../nodes/LineHeight';
import BackgroundColor from '../nodes/BackgroundColor';
import FontSize from '../nodes/fontSize';
import Blockquote from '../nodes/Blockquote';
import Image from '@tiptap/extension-image';
import ImageResize from '../nodes/ImageResize';
import History from '@tiptap/extension-history';

interface ExtensionDefinition {
  extension?: any;
  config?: Record<string, any>;
  enabled: boolean;
}

export const allExtensions: Record<string, ExtensionDefinition> = {
  document: { extension: Document, config: {}, enabled: true },
  history: { extension: History, config: {}, enabled: true },
  text: { extension: Text, config: {}, enabled: true },
  general: { extension: GeneralExtension, config: {}, enabled: true },
  heading: { extension: Heading, config: { levels: [1, 3, 5] }, enabled: true },
  paragraph: { extension: Paragraph, config: {}, enabled: true },
  blank: { extension: Blank, config: {}, enabled: true }, // Disabled by default
  link: {
    extension: Link,
    config: {
      priority: 1000,
      openOnClick: false,
      HTMLAttributes: {
        class: 'href cursor-pointer inline-block',
        'data-preview-link': false,
      },
    },
    enabled: true,
  },
  bold: { extension: Bold, config: {}, enabled: true },
  italic: { extension: Italic, config: {}, enabled: true },
  strike: { extension: Strike, config: {}, enabled: true },
  underline: { extension: Underline, config: {}, enabled: true },
  image: {
    extension: Image,
    config: {
      inline: true,
    },
    enabled: true,
  },
  image_resize: { extension: ImageResize, config: {}, enabled: true },
  tooltip: { extension: Tooltip, config: {}, enabled: true }, // Disabled by default
  list_item: { extension: ListItem, config: {}, enabled: true },
  ordered_list: {
    extension: OrderedList,
    config: {
      keepAttributes: true,
      keepMarks: true,
    },
    enabled: true,
  },
  bullet_list: {
    extension: BulletList,
    config: {
      keepAttributes: true,
      keepMarks: true,
    },
    enabled: true,
  },
  text_style: {
    extension: TextStyle,
    config: { priority: 1500 },
    enabled: true,
  },
  keyboard: { extension: GeneralKeyboardExtensions, config: {}, enabled: true },
  block_quote: { extension: Blockquote, config: {}, enabled: true },
  color: { extension: Color, config: { types: ['textStyle'] }, enabled: true },
  font_size: {
    extension: FontSize,
    config: { types: ['textStyle', 'heading'] },
    enabled: true,
  },
  horizontal_rule: {
    extension: HorizontalRule,
    config: {
      HTMLAttributes: {
        class: 'py-2',
      },
    },
    enabled: true,
  },
  background_color: {
    extension: BackgroundColor,
    config: { types: ['paragraph', 'heading', 'orderedList', 'bulletList'] },
    enabled: true,
  },
  indent: {
    extension: Indent,
    config: {
      types: [
        'paragraph',
        'heading',
        'orderedList',
        'bulletList',
        'blockquote',
      ],
      minLevel: 0,
      maxLevel: 60,
    },
    enabled: true,
  },
  direction: { extension: TextDirection, config: {}, enabled: true },
  hard_break: { extension: HardBreak, config: {}, enabled: true },
  line_height: { extension: LineHeight, config: {}, enabled: true },
  highlight: {
    extension: Highlight,
    config: { multicolor: true },
    enabled: true,
  },
  text_align: {
    extension: TextAlign,
    config: {
      types: [
        'orderedList',
        'bulletList',
        'heading',
        'paragraph',
        'blockquote',
      ],
      defaultAlignment: 'justify',
    },
    enabled: true,
  },
  placeholder: {
    extension: Placeholder,
    config: { placeholder: 'write something here', considerAnyAsEmpty: true },
    enabled: true,
  },
  table: {
    extension: Table,
    config: {
      resizable: false,
      HTMLAttributes: { class: 'user-content-table' },
    },
    enabled: true,
  },
  table_row: { extension: TableRow, config: {}, enabled: true },
  table_header: { extension: TableHeader, config: {}, enabled: true },
  table_cell: { extension: TableCell, config: {}, enabled: true },
};

// Merge client configurations with default configurations
export const useRichTextEditorExtensions = (extensions = {}) => {
  const extArray = [];

  // Iterate over all defined extensions
  for (const [key, { extension, config, enabled }] of Object.entries(
    allExtensions,
  )) {
    const clientConfig = extensions[key];

    if (clientConfig === false || (!enabled && clientConfig !== true)) continue; // Skip if explicitly set to false or disabled by default without override

    const mergedConfig =
      clientConfig === true ? config : { ...config, ...clientConfig };
    //@ts-ignore
    extArray.push(extension ? extension?.configure(mergedConfig) : key);
  }

  // Include any additional custom extensions provided by the client
  for (const [key, value] of Object.entries(extensions)) {
    if (!allExtensions[key]) {
      //@ts-ignore
      extArray.push(value);
    }
  }
  return extArray;
};
