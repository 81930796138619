import { useScriptTag } from '@vueuse/core';
import { onUnmounted } from 'vue';

declare const grecaptcha: any;

const executeRecaptcha = (siteKey: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (typeof grecaptcha !== 'undefined') {
      grecaptcha.ready(function () {
        grecaptcha
          .execute(siteKey, { action: 'submit' })
          .then((token: string) => {
            resolve(token);
          })
          .catch(() => {
            const error = new Error(
              'Request error, please reload the page and try again',
            );
            reject(error);
          });
      });
    } else {
      resolve('');
    }
  });
};

export const useAuthCheck = (
  siteKey: string = import.meta.env.VITE_RECAPTCHA_KEY,
): (() => Promise<string | undefined>) => {
  if (siteKey) {
    useScriptTag(`https://www.google.com/recaptcha/api.js?render=${siteKey}`);

    onUnmounted(() => {
      const nodeBadge = document.querySelector('.grecaptcha-badge');
      nodeBadge?.remove();
    });

    return () => executeRecaptcha(siteKey);
  }

  return () => Promise.resolve(undefined);
};
