import Blockquote from '@tiptap/extension-blockquote';

export default Blockquote.extend({
  defining: true,
  addAttributes() {
    return {
      ...this.parent?.(),
      textAlign: {
        default: 'inherit',
        parseHTML: element => element.style.textAlign || 'inherit',
        renderHTML: attributes => {
          if (!attributes.textAlign) {
            return {};
          }
          let align;
          switch (attributes.textAlign) {
            case 'left':
              align = 'end';
              break;
            case 'right':
              align = 'start';
              break;
            default:
              align = attributes.textAlign;
              break;
          }
          return {
            style: `text-align: ${attributes.textAlign}; align-items: ${align};`,
          };
        },
      },
    };
  },
});
