import { getMarksBetween } from '@tiptap/vue-3';
import { computed, ref } from 'vue';
import { get } from 'lodash';
import { useRichTextEditor } from './useTiptapEditor';
import { getOptions } from './useEditorOptions';

import {
  fontSizeOptions,
  typographyOptions,
  spacingOptions,
  getCleanClass,
  editors,
  getEditor,
  isEditorRTL,
  configureEditorStyle,
  undoEditorStyle,
} from './utils';

const typographyRef = ref('טקסט פסקה');
const alignmentRef = ref();
const directionRef = ref();
const activeTypo = ref();

const getFontSizeOptions = computed(() => {
  const currentSelection = activeTypo.value?.attributes?.class || 'body';
  return fontSizeOptions.filter(option =>
    option.applyTo.includes(currentSelection),
  );
});
import {
  AlignRight,
  AlignLeft,
  PilcrowLeft,
  PilcrowRight,
} from 'lucide-vue-next';

export function useEditor() {
  const createEditor = ({
    id,
    model,
    editable,
    placeholder = 'לכתוב משהו כאן',
    onUpdate,
    onPaste = null,
    extensions = {},
    keyboard = true,
    direction = 'rtl',
  }) => {
    // Ensure the placeholder is applied if no custom configuration is provided

    //@ts-ignore
    if (extensions.placeholder === undefined && placeholder) {
      //@ts-ignore
      extensions.placeholder = { placeholder };
    }
    editors.set(id, {
      direction,
      enables: extensions,
      editor: useRichTextEditor({
        editable,
        model,
        onUpdate,
        keyboard,
        //@ts-ignore
        onSelectionUpdate,
        onPaste,
        extensions,
      }),
    });

    alignmentRef.value = isEditorRTL(id) ? AlignRight : AlignLeft;
    directionRef.value = isEditorRTL(id) ? PilcrowLeft : PilcrowRight;
  };

  const getSelected = (id, type) => {
    switch (type) {
      case 'highlight':
      case 'textStyle':
        return get(getCurrentMark(id, type), 'mark.attrs.color', '');

      case 'lineHeight':
        return get(
          getCurrentMark(id, 'lineHeight'),
          `mark.attrs.${type}`,
          spacingOptions.find(option => option.default && option.type == 'line')
            ?.attributes,
        );
      case 'fontSize':
        return (
          get(getCurrentMark(id, 'textStyle'), `mark.attrs.${type}`, '') ||
          getCurrentNodeAttr(id, type)
        );

      case 'backgroundColor':
        return getCurrentNodeAttr(id, 'color');

      case 'tableCellBackgroundColor':
        return getCurrentNodeAttr(id, 'backgroundColor', [
          //@ts-ignore
          'tableCell',
          //@ts-ignore
          'tableHeader',
        ]);

      case 'tableCellBorderColor':
        return getCurrentNodeAttr(id, 'borderColor', [
          //@ts-ignore
          'tableCell',
          //@ts-ignore
          'tableHeader',
        ]);
      default:
        return getCurrentNodeAttr(id, type);
    }
  };

  const onSelectionUpdate = ({ editor }) => {
    const { $from } = editor.state.selection;
    let node = $from.node($from.depth) ?? $from.parent;

    let foundActive = false;
    for (const option of typographyOptions) {
      const isActive =
        editor.isActive(
          option.actionMethod === 'setHeading' ? 'heading' : 'paragraph',
        ) && getCleanClass(node, null).includes(option.attributes.class);

      if (isActive) {
        activeTypo.value = option;
        foundActive = true;
        break;
      }
    }

    if (!foundActive) {
      activeTypo.value = null;
    }

    typographyRef.value = activeTypo.value?.text || 'טקסט פסקה';
  };

  const getCurrentMark = (id, mark) => {
    if (!getEditor(id)) return;
    const { from, to } = getEditor(id).value.state.selection;
    return getMarksBetween(from, to, getEditor(id).value.state.doc).find(
      selected => selected.mark.type.name == mark,
    );
  };
  const getCurrentNodeAttr = (id, attr, nodeType = []) => {
    if (!getEditor(id)) return;
    let val;
    const { from, to } = getEditor(id).value?.state.selection ?? null;
    getEditor(id).value?.state.doc.nodesBetween(from, to, node => {
      if (node.attrs[attr]) {
        //@ts-ignore
        if (nodeType.length === 0 || nodeType.includes(node.type.name)) {
          val = node.attrs[attr];
          return node.attrs[attr];
        }
      }
    });
    return val;
  };

  const getOptionsGroups = (id, disabledHighlight) => {
    if (!getEditor(id)) return;
    return getOptions(
      id,
      disabledHighlight,
      alignmentRef,
      directionRef,
      typographyRef,
      activeTypo,
      getFontSizeOptions,
    );
  };

  return {
    createEditor,
    getEditor,
    getOptionsGroups,
    getCurrentMark,
    getSelected,
    configureEditorStyle,
    undoEditorStyle,
  };
}
