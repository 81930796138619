import Heading from '@tiptap/extension-heading';
export default Heading.extend({
  name: 'heading',
  key: 'heading',
  addAttributes() {
    return {
      ...this.parent?.(),
      class: {
        default: 'mb-4 h1',
        // Take the attribute values
        renderHTML: attributes => {
          // … and return an object with HTML attributes.
          return {
            class: `${attributes.class}`,
          };
        },
      },
      textAlign: {
        default: 'inherit',
        parseHTML: element => element.style.textAlign || 'inherit',
        renderHTML: attributes => {
          if (!attributes.textAlign) {
            return {};
          }

          return {
            style: `text-align: ${attributes.textAlign}`,
          };
        },
      },
      fontSize: {
        default: '20px',
        parseHTML: element => element.style.fontSize || '20px',
        renderHTML: attributes => {
          if (!attributes.fontSize) {
            return {};
          }

          return {
            style: `font-size: ${attributes.fontSize}`,
            'font-size': attributes.fontSize,
          };
        },
      },
    };
  },
  addCommands() {
    return {
      ...this.parent?.(),
      setHeading:
        attributes =>
        ({ editor, chain }) => {
          const { state } = editor;
          const { from } = state.selection;
          const node = state.doc.resolve(from).node(1);
          let textAlign = null;
          if (node) {
            textAlign = node.attrs.textAlign;
          }

          return chain()
            .setNode(this.name, { ...attributes, textAlign })
            .run();
        },
    };
  },
});
