<template>
  <div
    class="hidden bg-primary-50 bg-orange-50 bg-green-50 bg-red-50 stroke-primary-50 stroke-orange-50 stroke-green-50 stroke-red-50 border-green-500 border-green-600 fill-green-600 border-secondary-950"></div>
  <div class="relative grow overflow-x-hidden">
    <AppHeader />
    <RouterView v-slot="{ Component, route }">
      <Transition
        enter-active-class="transition ease-in-out duration-700 opacity"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in-out duration-100 opacity"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0">
        <div
          :key="route.path"
          class="overflow-x-hidden flex flex-col items-center justify-start no-scrollbar"
          :style="{ height: `calc(100vh - 56px)` }">
          <component :is="Component" />
        </div>
      </Transition>
    </RouterView>
    <!--    FIXME:: this code is here to fix the problem of missing classes -->
    <div
      class="hidden text-orange-500 bg-red-50 text-red-500 fill-red-500 stroke-red-50 border-red-300 border-green-300 border-orange-300 bg-primary-50 bg-orange-50 bg-green-50 stroke-primary-50 stroke-orange-50 stroke-green-50 border border-orange-500 fill-orange-500 stroke-red-50"></div>
  </div>
  <LoadingOverlay
    v-model:active="systemStore.isLoading"
    color="#E0F2FE"
    :can-cancel="false"
    :is-full-page="true"/>
</template>

<script setup lang="ts">
import { useSystemStore } from '/@/app/store/system';
import AppHeader from '/src/views/layouts/AppHeader.vue';
import { resolveComponent } from 'vue';

const LoadingOverlay = resolveComponent('LoadingOverlay');
const systemStore = useSystemStore();
</script>
