import { createApp, defineAsyncComponent } from 'vue';
import { createPinia } from 'pinia';
import { router } from '/@/router';
import vClickOutside from 'click-outside-vue3';

import {
  SentryPlugin,
  i18nPlugin,
  PrimeVuePlugin,
  VersionPlugin,
} from '@amit/composables/plugins';
import { createAuthService, Origin, Scope } from '@amit/auth';

import 'vue-loading-overlay/dist/css/index.css';
import '@nwire/amit-design-system/dist/style.css';
import './styles/base.css';
import 'vue3-carousel/dist/carousel.css';

import App from './App.vue';
import NotificationToast from '/@/views/partials/NotificationToast.vue';

const app = createApp(App);
const store = createPinia();

app.use(createAuthService(router), {
  origin: Origin.Console,
  scope: [Scope.Teacher, Scope.Editor, Scope.Admin],
});

app.use(store);
app.use(SentryPlugin, { router, store });
app.use(i18nPlugin);
app.use(PrimeVuePlugin);
app.use(VersionPlugin);
app.use(router);

app.component('NotificationToast', NotificationToast);
app.component(
  'LoadingOverlay',
  defineAsyncComponent(() => import('vue-loading-overlay')),
);
app.use(vClickOutside);

app.mount('#app');
