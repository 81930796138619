<template>
  <li :class="itemClasses">
    <div v-if="$slots.prefix" class="mr-2">
      <slot name="prefix" />
    </div>
    <slot />
    <div v-if="$slots.suffix" class="ml-2">
      <slot name="suffix" />
    </div>
  </li>
</template>
<script lang="ts" setup>
import { useListGroupItemClasses } from './composables/useListGroupItemClasses';
import { toRefs } from 'vue';

const props = defineProps({
  hover: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { itemClasses } = useListGroupItemClasses(toRefs(props));
</script>
