import { createI18n } from 'vue-i18n';
import messages from '@intlify/unplugin-vue-i18n/messages';

import type { App, Plugin } from 'vue';

/**
 * Vue plugin for integrating i18n.
 */
export const i18nPlugin: Plugin = {
  /**
   * Install the i18nPlugin plugin.
   * @param app - The Vue application instance.
   */
  install(app: App): void {
    const i18n: Plugin = createI18n({
      locale: 'he',
      fallbackLocale: 'he',
      messages,
    });
    app.use(i18n);
  },
};

export default i18nPlugin;
