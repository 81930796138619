//@ts-nocheck
import { computed, nextTick, ref, watchEffect } from 'vue';
import type { Api as RevealApi } from 'reveal.js';
import Reveal from 'reveal.js';
import { createEventHook } from '@vueuse/core';
import type { PageSlide, Presentation } from '@/apps/slideshow/types';
import { get } from 'lodash';
import { useLocale } from '@/composables/useLocale';
import { collect } from 'collect.js';

let presentationManager: RevealApi | any = null;
const defaultSettings: Reveal.Options | any = {
  controls: false,
  progress: false,
  history: false,
  center: true,
  embedded: true,
  rtl: true,

  autoSlide: false, // ms
  transition: 'slide',
  transitionSpeed: 'default',
  backgroundTransition: 'fade',
  controlsLayout: 'bottom-right',

  previewLinks: true,
  disableLayout: false,
  slideNumber: false,

  respondToHashChanges: false,
  keyboard: false,
  overview: true,
  touch: false,
  showNotes: false,
  autoPlayMedia: false,
  preloadIframes: false,
  width: '100%',
  height: '100%',
  hideInactiveCursor: false,
  focusBodyOnPageVisibilityChange: true,
  scrollActivationWidth: null,
};

const onOutlineChange = createEventHook<number>();
const onNavigationChange = createEventHook<any>();
const onTrackChange = createEventHook<any>();
const onNext = createEventHook<any>();
const onPrev = createEventHook<any>();
const onSlide = createEventHook<any>();
const onReady = createEventHook<any>();
const onAction = createEventHook<any>();
const onClose = createEventHook<any>();
const onFinish = createEventHook<any>();
const onEmit = createEventHook<any>();
const onSubmit = createEventHook<any>();
const onPause = createEventHook<any>();
const onResume = createEventHook<any>();
const onVisibilityChange = createEventHook<any>();
const locale = ref('he');
const user: any = ref({});
const currentSlide = ref<PageSlide | any>({});
const context = ref<any>();
const nextSlide = ref<PageSlide | any>();
const slideshow = ref<Presentation | any>({ sections: [] });
const outline = ref<{ id: string; title: string } | any>([]);
const tracks = ref<string[]>([]);
const page = ref<string>();
const history = ref<Array<any>>([]);
const hasPrevSlide = ref<boolean>(false);
const rtl = computed(() => !!slideshow.value?.display?.rtl);
const slides = computed<PageSlide[]>(() =>
  slideshow.value.sections.flatMap(section =>
    section.pages.map(page => {
      page.state = {};
      return page;
    }),
  ),
);
const sections = computed(() => {
  let sectionIds = collect(trackSlides.value)
    .pluck('section')
    .unique()
    .toArray();
  return slideshow.value.sections.filter(section =>
    sectionIds.includes(section.id),
  );
});

const lastSectionIndex = computed(() => {
  if (!history.value.length) return slideshow.value.sections.length - 1;
  return slideshow.value.sections.findIndex(section =>
    section.pages.some(
      page => page.id === history.value[history.value.length - 1],
    ),
  );
});

const trackSlides = computed<PageSlide[]>(() =>
  slides.value.filter(slide =>
    ['default', ...tracks.value].includes(slide.track),
  ),
);

const setCurrentSlide = () => {
  let status = presentationManager.getState();
  currentSlide.value = (trackSlides.value[status.indexh] as PageSlide) ?? {};
  currentSlide.value.fragments = [];
  hasPrevSlide.value = !presentationManager?.isFirstSlide();
};

const next = () => {
  if (!presentationManager.nextFragment()) {
    if (nextSlide.value) {
      toPage(nextSlide.value.id);
    }
  }
};

const toSlide = index => {
  presentationManager?.slide(index);
};

const toPage = id => {
  let index = trackSlides.value.findIndex((page: PageSlide) => page.id === id);
  toSlide(index);
};

const handlePrev = () => {
  onPrev.trigger(context.value);
  //prev on fragment
  if (presentationManager.prevFragment()) {
    const availableFragments = presentationManager.availableFragments();
    if (!availableFragments?.prev) {
      currentSlide.value.fragments = [];
    }
    return;
  }

  const currentSlideIndex = history.value?.findIndex(
    item => item === currentSlide.value.id,
  );
  const prevSlide = get(history.value, [currentSlideIndex - 1], null);
  //prev on history
  if (prevSlide) {
    toPage(history.value[currentSlideIndex - 1]);
    return;
  }

  //prev on presentation
  rtl.value ? presentationManager.right() : presentationManager.left();
};

const sleep = async delay =>
  await new Promise(resolve => setTimeout(resolve, delay));

const recalculateSlide = async () => {
  await sleep(50);
  presentationManager?.layout();
};

const handleNext = () => {
  context.value.nextEnabled() && onNext.trigger(context.value);
  if (presentationManager?.isLastSlide()) {
    onFinish.trigger(context.value);
  }
};

const createContext = () => {
  setCurrentSlide();

  return {
    getId: () => currentSlide.value.id,
    state: () => currentSlide.value.state,
    settings: currentSlide.value.display,
    blocks: () => currentSlide.value.blocks,
    flatBlocks: () => {
      return currentSlide.value.blocks.flatMap(block => {
        if (block.name === 'two_columns') {
          return [...block.content.left, ...block.content.right];
        }
        return block;
      });
    },
    push: fragment => {
      if (fragment?.message) {
        currentSlide.value.fragments.push(fragment);
        presentationManager.sync();
      }
    },
    get: () => currentSlide.value.fragments.length,
    updateState: (block, value) => {
      const state = currentSlide.value.state;
      if (Array.isArray(state[block]) && typeof value === 'string') {
        state[block] = [value];
      } else {
        state[block] = value;
      }
    },
    sync: ({ instructions }) => {
      if (instructions) {
        nextSlide.value = trackSlides.value.find(
          slide => slide.id === instructions,
        );
        if (
          nextSlide.value?.settings?.once &&
          history.value.includes(nextSlide.value.id)
        ) {
          nextSlide.value = null;
        }
      } else {
        let status = presentationManager.getState();
        nextSlide.value = trackSlides.value[status.indexh + 1] as PageSlide;
      }
      return nextSlide.value;
    },
    setDisabled: isDisabled => {
      currentSlide.value.disabled = isDisabled;
    },
    setLoading: status => (currentSlide.value.loading = status),
    setState: state => (currentSlide.value.state = state),
    setRules: rules => (currentSlide.value.rules = rules),
    next: ({ state, disabled = false, rules = [] }) => {
      if (state && nextSlide.value) {
        nextSlide.value.state = state;
        nextSlide.value.disabled = disabled;
        nextSlide.value.rules = rules;
      }
      nextTick(() => {
        next();
      });
    },
    nextEnabled: () => !(currentSlide.value.disabled ?? true),
    loading: () => !!currentSlide.value.loading,
  };
};

export function createPresentation({
  slideshow: sl,
  outline: ot,
  tracks: tr,
  page: pg = null,
  history: hs = [],
  immediate = true,
  locale: lc,
  user: us,
}) {
  const canStart = ref(immediate);

  watchEffect(() => {
    slideshow.value = sl.value;
    outline.value = ot.value;
    if (pg?.value) {
      page.value = pg.value;
    }

    if (lc?.value) {
      locale.value = lc.value;
    }

    if (us?.value) {
      user.value = us.value;
    }

    if (slideshow.value.sections.length && canStart.value) {
      nextTick(() => {
        initialize();
      });
    }
  });

  watchEffect(() => {
    if (tr?.value) {
      tracks.value = tr.value;
      sync();
    }
  });

  watchEffect(() => {
    if (hs?.value) {
      history.value = hs.value.filter(page =>
        trackSlides.value.some(slide => slide.id === page),
      );
    }
  });

  return {
    getLocalizedText,
    toPage,
    toSlide,
    start: () => {
      canStart.value = true;
    },
    hasPrevSlide,
    handleNext,
    handlePrev,
    currentSlide,
    onOutlineChange: onOutlineChange.on,
    onNavigationChange: onNavigationChange.on,
    onTrackChange: onTrackChange.on,
    onClose: onClose.on,
    onNext: onNext.on,
    onPrev: onPrev.on,
    onSlide: onSlide.on,
    onReady: onReady.on,
    onAction: onAction.on,
    onFinish: onFinish.on,
    onEmit: onEmit.on,
    onSubmit: onSubmit.on,
  };
}

const { getLocalizedText } = useLocale(locale);

const initializePresentation = async () => {
  const revealConfig: Reveal.Options = {
    ...defaultSettings,
    ...slideshow.value.display,
  };
  presentationManager = new Reveal(
    document.getElementById(slideshow.value.id),
    revealConfig,
  );
  await presentationManager.initialize();

  presentationManager.on('slidechanged', event => {
    setCurrentSlide();
    nextTick(() => {
      onSlide.trigger({ event: event, context: context.value });
    });
  });

  context.value = createContext();
  nextTick(() => {
    const pageId = page.value || history.value?.[history.value.length - 1];
    pageId && toPage(pageId);
    onReady.trigger({ context: context.value });
  });
};

const sync = () => {
  if (!presentationManager) {
    return;
  }
  //Stupid hack cause sync() not working
  nextTick(() => {
    let status = presentationManager.getState();
    presentationManager?.slide(status.indexh);
  });
};

const initialize = async () => {
  presentationManager?.destroy();

  if (!slideshow.value) {
    throw new Error('cannot initialize presentation');
  }

  await initializePresentation();

  onAction.on(({ event: { block, value } }) => {
    if (block.settings?.trackSelection) {
      const track = block.name === 'persona_selection' ? value : value[0];
      const optionsKey =
        block.name === 'persona_selection' ? 'personas' : 'options';
      const blockTracks: string[] =
        block.content?.[optionsKey]?.map(choice => choice.id) || [];
      const index = tracks.value.findIndex(track =>
        blockTracks.includes(track),
      );
      if (index > -1) {
        tracks.value.splice(index);
      }
      tracks.value.push(track);
      sync();
      onTrackChange.trigger({ block, track });
    }
  });
};

export function useReveal() {
  return {
    locale,
    user,
    rtl,
    sections,
    slideshow,
    lastSectionIndex,
    trackSlides,
    outline,
    context,
    next,
    toPage,
    toSlide,
    handleNext,
    handlePrev,
    getLocalizedText,
    recalculateSlide,
    onOutlineChange: onOutlineChange.on,
    onNavigationChange: onNavigationChange.on,
    onTrackChange: onTrackChange.on,
    onVisibilityChange: onVisibilityChange.on,
    onClose: onClose.on,
    onNext: onNext.on,
    onPrev: onPrev.on,
    onSlide: onSlide.on,
    onReady: onReady.on,
    onAction: onAction.on,
    onEmit: onEmit.on,
    onPause: onPause.on,
    onResume: onResume.on,
    hasPrevSlide,
  };
}

export function useInteractions() {
  return {
    onOutlineChange,
    onNavigationChange,
    onTrackChange,
    onVisibilityChange,
    getLocalizedText,
    onClose,
    onNext,
    onPrev,
    onSlide,
    onReady,
    onAction,
    onFinish,
    onEmit,
    onSubmit,
    onPause,
    onResume,
  };
}
