<template>
  <component
    :is="itemIcon"
    :size="size"
    :color="color"
    :stroke-width="strokeWidth"/>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import * as icons from 'lucide-vue-next';

const props = defineProps({
  name: {
    type: String,
    default: null,
  },
  size: {
    type: Number,
    default: null,
  },
  color: {
    type: String,
    default: null,
  },
  strokeWidth: {
    type: Number,
    default: 3,
  },
});

const itemIcon = computed(() => icons[props.name]);
</script>
