import type { AxiosResponse } from 'axios';
import type { FormContext } from 'vee-validate';
import type { ComposerTranslation } from 'vue-i18n';

/**
 * Handles server errors and sets appropriate error messages.
 * @param response - The Axios response object.
 * @param setFieldError - Function to set field-specific error messages (from vee-validate).
 * @param t - The translation function (from vue-i18n).
 */
export const handleServerErrors = (
  response: AxiosResponse,
  setFieldError: FormContext['setFieldError'],
  t: ComposerTranslation,
): void => {
  let attemptsLeft = +response.headers['rate-limit-remaining'];
  setFieldError('server_errors', t('errors.generic'));

  if (response.data?.message === 'You are not human') {
    setFieldError('server_errors', t('errors.recaptcha'));
  }

  if (
    response.data?.name === 'DocumentNotFoundError' ||
    response.status === 403
  ) {
    setFieldError('server_errors', t('errors.wrong'));
  }

  if (attemptsLeft < 3) {
    setFieldError('server_errors', t('errors.attempts', attemptsLeft + 1));
  }

  if (response.status === 429) {
    setFieldError('server_errors', t('errors.too_many'));
  }
};
