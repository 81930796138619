import type { App, Plugin } from 'vue';

declare const __APP_VERSION__: string;

/**
 * Vue plugin for logging the current version.
 */
export const VersionPlugin: Plugin = {
  /**
   * Install the VersionPlugin plugin.
   * @param app - The Vue application instance.
   */
  install(app: App): void {
    const version = __APP_VERSION__;

    app.config.globalProperties.$version = version;

    console.log(
      '%cHello, Amit! v' + version,
      'color: white; font-size: 26px; padding: 10px; background-color: #0FA9FF;',
    );
  },
};

export default VersionPlugin;
