<template>
  <div class="h-screen flex flex-col">
    <MainHeader
      :user="user"
      :sidebar-visible="sidebarVisible"
      @logout="$emit('logout')"
      @toggle-sidebar="toggleSidebar"/>

    <div class="flex overflow-x-hidden flex-1 transition-all">
      <MainSidebar
        ref="sidebar"
        :sidebar-visible="sidebarVisible"
        :user="user"
        :school="school"
        :system="system"/>
      <main class="flex-1 flex overflow-auto h-full">
        <div
          class="max-w-full lg:max-w-5xl p-4 flex-1 min-w-0 flex flex-col gap-5">
          <div v-if="$slots.header || $slots.actions" class="sticky top-0">
            <slot v-if="$slots.header" name="header"></slot>
            <slot v-if="$slots.actions" name="actions"></slot>
          </div>

          <div class="content flex flex-1 flex-col">
            <slot />
            <!-- <div class="spacer h-20"></div> -->
          </div>
        </div>

        <WidgetsBar>
          <slot name="widgets"></slot>
        </WidgetsBar>
      </main>
    </div>
  </div>
  <MainDrawer />
  <BaseToast />
</template>

<script setup lang="ts">
import { ref, provide, reactive } from 'vue';
import { onClickOutside } from '@vueuse/core';

import WidgetsBar from './components/WidgetsBar.vue';
import MainHeader from './components/MainHeader.vue';
import MainSidebar from './components/MainSidebar.vue';
import MainDrawer from './components/MainDrawer.vue';
import BaseToast from './components/BaseToast.vue';

import { PROVIDE_TELEPORT_TARGETS } from '@/apps/slideshow/teleport/const';

defineEmits(['logout']);
defineProps({
  user: {
    type: Object,
    default: () => {},
  },
  school: {
    type: Object,
    default: () => {},
  },
  system: {
    type: Object,
    default: () => {},
  },
});

const sidebar = ref(null);

provide(PROVIDE_TELEPORT_TARGETS, reactive({}));

onClickOutside(sidebar, () => {
  if (!sidebarVisible.value) {
    return;
  }

  setTimeout(() => {
    sidebarVisible.value = false;
  }, 150);
});

const sidebarVisible = ref(false);
const toggleSidebar = () => {
  setTimeout(() => {
    sidebarVisible.value = !sidebarVisible.value;
  }, 100);
};
</script>

<style lang="scss">
.custom-grid {
  @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4;
}

.custom-grid-item {
  @apply border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-32 md:h-64;
}

.custom-large-box {
  @apply border-2 border-dashed rounded-lg border-gray-300 dark:border-gray-600 h-96 mb-4;
}

.content {
  > div {
    @apply flex-1 flex flex-col overflow-auto;
  }
}
</style>
