export default {
  root: {
    class: '',
  },
  menu: {
    class: [
      // Flexbox
      'flex flex-1',

      // Spacing
      'list-none',
      'p-0 m-0',

      // Colors
      'bg-transparent',
      'border-b-1 border-secondary-250',
      'text-secondary-900',
    ],
  },
  menuitem: {
    class: 'mr-0',
  },
  action: ({ context, state }) => ({
    class: [
      'relative',

      // Font
      'font-simplerRegular leading-none text-sm',

      // Flexbox and Alignment
      'flex items-center flex-row-reverse gap-1',

      // Spacing
      'py-2 px-2 me-2',
      '-mb-px',

      // Colors and Conditions
      {
        // 'border-surface-200':
        //   state.d_activeIndex !== context.index,
        // 'bg-transparent':
        //   state.d_activeIndex !== context.index,
        'text-secondary-450': state.d_activeIndex !== context.index,

        // 'bg-white dark:bg-surface-900':
        //   state.d_activeIndex === context.index,
        'border-primary border-b-1': state.d_activeIndex === context.index,
        'text-primary': state.d_activeIndex === context.index,
      },

      // States
      // 'focus:outline-none focus:outline-offset-0 focus-visible:ring-1 ring-inset focus-visible:ring-primary-400 dark:focus-visible:ring-primary-300',
      {
        '!font-normal hover:text-secondary-500 hover:border-b-secondary-300 hover:border-b-1':
          state.d_activeIndex !== context.index,
      },

      // Transitions
      'transition-all duration-200',

      // Misc
      'cursor-pointer select-none text-decoration-none',
      'overflow-hidden',
      'user-select-none',
    ],
  }),
  icon: {
    class: 'mr-2',
  },
};
